import React, { useEffect, useMemo } from 'react';
import {
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Stack,
    Heading,
    HStack,
    CloseButton,
    Text,
    Tag,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
} from '@chakra-ui/react'


import { CampaignSteps } from './campaignSteps';

import { AutoDatePicker, AutoUI, GeniouslyThemeProvider } from 'geniously-chat-ui';
import { CampaignProvider, useCampaignContext } from './campaignContexts';
import ColumnMappings from './columnMappings';
import { useApiFetch } from '../../utils/useApiHook';
import { useListContext } from './listContext';
import Moment from 'react-moment';




const CampaignEdit = ({ campaign, onChange, activeStepId, readOnly, onActiveStepIdChange }: {
    campaign: Campaign,
    activeStepId: any,
    readOnly?: boolean,
    onChange: (campaign: Campaign) => void,
    onActiveStepIdChange?: (activeStepId: any) => void

}) => {

    const [activeTab, setActiveTab] = React.useState(0)
    const [activeStepIdInternal, setActiveStepIdInternal] = React.useState(activeStepId)
    const SEQUENCE_TAB_ID = 1
    useEffect(() => {
        if (activeTab == SEQUENCE_TAB_ID) {
            setActiveStepIdInternal(activeStepId)
        }

    }, [activeStepId])

    useEffect(() => {
        if (activeTab == SEQUENCE_TAB_ID) {
            onActiveStepIdChange && onActiveStepIdChange(activeStepIdInternal)
        }
    }, [activeTab])



    return (
        <Stack backgroundColor="gray.50" p="10px" >
            <Tabs colorScheme='brand' onChange={setActiveTab}>
                <TabList>
                    <Tab>Sequences <Tag>{campaign?.steps?.length || 0}</Tag></Tab>
                    <Tab>Settings</Tab>

                </TabList>
                <TabPanels>

                    <TabPanel>
                        <HStack align="center" justify="space-between">
                            <HStack m="0x 10px" flexShrink={0} p="5px" background="white" borderRadius={4} border="1px solid lightgray" shadow="sm" alignSelf="start">
                                <Text fontSize="xs" fontWeight={900} color="gray">Start</Text>
                                <GeniouslyThemeProvider>
                                    {readOnly ? (<Text>{campaign.campaign_start_date ? <Moment>{campaign.campaign_start_date}</Moment> : "ASAP"}</Text>) : (
                                        <AutoDatePicker placeholder="ASAP" value={campaign.campaign_start_date} onApply={(val) => onChange({
                                            ...campaign,
                                            campaign_start_date: val
                                        })} />
                                    )}
                                    {!campaign.campaign_start_date && <Text fontSize="xs" fontWeight={600} color="gray">(immediately)</Text>}
                                </GeniouslyThemeProvider>

                            </HStack>
                            {!readOnly && <Button size="sm" colorScheme='brand' onClick={() => {
                                let newStep = { step_id: campaign.steps.map((s) => s.step_id).reduce((a, b) => Math.max(a, b), 0) + 1 }
                                onChange({
                                    ...campaign,
                                    steps: [...campaign.steps, newStep]
                                })
                            }}>Add step</Button>}
                        </HStack>

                        <CampaignSteps
                            readOnly={readOnly}
                            activeStepId={activeStepId}
                            onActiveStepIdChange={(activeStepId) => {
                                onActiveStepIdChange && onActiveStepIdChange(activeStepId)
                            }}
                            steps={campaign?.steps || []} onChange={(steps) => {
                                onChange({ ...campaign, steps: steps.filter((step: any) => !!step) })
                            }} />
                    </TabPanel>
                    <TabPanel>
                        <CampaignSettings campaign={campaign} onChange={onChange} readOnly={readOnly} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
    );
};

const CampaignSettings = ({ campaign, onChange, readOnly }: { campaign: Campaign, onChange: (campaign: Campaign) => void, readOnly?: boolean }) => {
    const campaignContext = useCampaignContext()
    const listContext = useListContext()
    const liAccounts = useApiFetch<{ name: string }[]>("/inbox/accounts?channel=linkedin", { static: true })
    const emailAccounts = useApiFetch<{ name: string }[]>("/inbox/accounts?channel=email", { static: true })


    return <Stack spacing={6}>
        <GeniouslyThemeProvider>

            <AutoUI
                value={{ ...campaign, autoinclude_new_contacts: campaign.autoinclude_new_contacts || false }}
                showOnlySchemaFields
                onValueChange={!readOnly ? (val) => onChange(val) : undefined}
                validationErrors={campaignContext?.campaignErrors && (Object.keys(campaignContext.campaignErrors).map((key) => ({ loc: key.split("."), msg: campaignContext.campaignErrors[key] })))}
                schema={{
                    type: "object",
                    properties: {
                        "name": {
                            type: "string",
                            title: "Campaign name",

                            description: "The name of the campaign"
                        },
                        "linkedin_accounts": {
                            type: "array",
                            title: "LinkedIn outreach accounts",
                            items: {
                                type: "string"
                            },

                            enum: liAccounts?.data?.map(a => a.name),
                            description: "The LinkedIn accounts to use for outreach. You can select multiple accounts, to distribute the load evenly. For more targeted approach, selecting a column with LinkedIn account names will allow you to use specify the account per lead"
                        },
                        "email_accounts": {
                            type: "array",
                            title: "Email accounts",
                            items: {
                                type: "string"
                            },
                            enum: emailAccounts.data?.map((acc) => acc.name),
                            description: "The email accounts to use for outreach. You can select multiple accounts, to distribute the load evenly. For more targeted approach, selecting a column with email account names will allow you to use specify the account per lead"
                        },
                        "extra_filter_description": {
                            type: "string",
                            title: "Filter description",
                            description: "The filter to use for selecting leads to outreach. ",
                            example: "Company size bigger than 1000 and industry = 'IT'"
                        },
                        "ab_testing_key": {
                            type: "string",
                            title: "A/B Testing key column",
                            enum: listContext?.columns?.filter(c => c.type == "string" && (c as any).enum).map((col) => col.name),
                            description: "The A/B Testing Key Column designates a column whose values categorize messages, enabling performance comparisons across each category."
                        },
                        // "autoinclude_new_contacts": {
                        //     type: "boolean",
                        //     title: "Autoinclude new contacts",
                        //     description: "If enabled new contacts will be automatically included in the campaign"
                        // },



                    }
                }}
            />
            <Stack spacing={2}>
                <Text fontWeight={600} >
                    Column Mappings
                </Text>
                <ColumnMappings
                    readOnly={readOnly}
                    allowExtraColumns
                    errors={campaignContext.campaignErrors && Object.keys(campaignContext.campaignErrors).filter((key) => key.startsWith("contact_column_mappings.")).reduce((acc, key) => ({ ...acc, [key.replace("contact_column_mappings.", "")]: campaignContext.campaignErrors[key] }), {})}
                    standardColumns={[
                        { name: 'full_name', title: 'Full Name', type_filter: 'string' },
                        { name: 'first_name', title: 'First Name', type_filter: 'string' },
                        { name: 'last_name', title: 'Last Name', type_filter: 'string' },
                        { name: 'email', title: 'Email', type_filter: 'string' },
                        { name: 'linkedin_url', title: 'LinkedIn URL', type_filter: 'string' },
                        { name: 'position', title: 'Position', type_filter: 'string' },
                        { name: 'company', title: 'Company', type_filter: 'string' },
                        { name: 'company_website', title: 'Company Website', type_filter: 'string' },
                    ]}
                    mapping={campaign.column_mappings as any}
                    onChange={(val) => onChange({ ...campaign, column_mappings: val as any })} />
            </Stack>

            <Accordion>


                <AccordionItem>
                    <AccordionButton>
                        <Text textAlign="left" fontWeight={700}>
                            Advanced Settings
                        </Text>
                        <AccordionIcon />

                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <AutoUI
                            value={campaign}
                            showOnlySchemaFields
                            onValueChange={!readOnly ? (val) => onChange(val) : undefined}
                            validationErrors={campaignContext?.campaignErrors && (Object.keys(campaignContext.campaignErrors).map((key) => ({ loc: key.split("."), msg: campaignContext.campaignErrors[key] })))}
                            schema={{
                                type: "object",
                                properties: {
                                    "behavior_if_existing_contact": {
                                        type: "string",
                                        title: "Behavior for existing contact",
                                        enum: ["skip_if_active", "skip", "cancel_old_and_process", "process_anyway"],
                                        description: "The behavior if the contact is already known to the system... skip_if_active: skip if the contact is already know to the system, skip: skip if the contact has already been in any campaign, cancel_old_and_process: cancel the old campaign and process the new one"
                                    }
                                }
                            }}
                        />

                    </AccordionPanel>
                </AccordionItem>

            </Accordion>


        </GeniouslyThemeProvider>
    </Stack>
}

export { CampaignEdit };
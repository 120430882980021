import { use } from 'echarts';
import React, { useEffect } from 'react';
import { MentionsInput, Mention } from 'react-mentions'
import { useListContext } from '../Campaigns/listContext';
import { border, Button, ButtonGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { IconInfoCircle, IconInfoSmall } from '@tabler/icons-react';
interface ColumnsAwareTextAreaProps {
    value: string;
    onApply: (value: string) => void;
    columns?: string[];
    showHelp?: boolean | string;
    supportMentions?: boolean;
    supportTemplateVariables?: boolean;
}

const ColumnsAwareTextArea: React.FC<ColumnsAwareTextAreaProps> = ({ value, onApply, columns: columnInput, supportMentions = true, supportTemplateVariables, showHelp }) => {
    const listContext = useListContext();
    const columns = columnInput || listContext?.columns?.map(c => c.name) || [];
    const handleApply = () => {
        onApply && onApply(tempValue);
    };
    const [tempValue, setTempValue] = React.useState(value);

    useEffect(() => {
        if (value !== tempValue) {
            setTempValue(value);
        }
    }, [value]);




    return (
        <Stack spacing={0}>

            <Stack border="1px solid lightgray" borderRadius="4px" background="white" _hover={{ border: "1px solid gray" }} fontSize="10pt" p="4px"
                css={`&  textarea:focus {
            outline: none;
            }
           
            `}>
                <MentionsInput className="mentionsTextArea" value={tempValue || ""}

                    onChange={(event, newValue) => {
                        setTempValue(newValue);
                    }} onBlur={() => handleApply()} >
                    {[supportMentions && <Mention
                        key="supportMentions"
                        trigger="@"
                        markup="`@(__id__)`"
                        data={columns.map((col_name, cid) => ({ id: col_name, display: col_name }))}
                        displayTransform={(id, display) => `${display}`}
                        appendSpaceOnAdd

                        style={{ backgroundColor: '#E6E6EE', borderRadius: "4px" }}
                    />,
                    supportTemplateVariables && <Mention
                        key="supportTemplateVariables"
                        trigger="{"
                        markup="{__id__}"
                        appendSpaceOnAdd
                        data={columns.map((col_name, cid) => ({ id: col_name, display: col_name }))}
                        displayTransform={(id, display) => `{${display}}`}
                        style={{ backgroundColor: '#FBE3C1' }}
                    />].filter(Boolean)}

                </MentionsInput>
            </Stack>
            {showHelp && <HStack fontSize="8pt" color="black.500" spacing={0}>
                <IconInfoSmall size="20px" />
                <Text>
                    {typeof (showHelp) == "boolean" ? (supportMentions && supportTemplateVariables ? "You can mention columns using @column_name or add column value {column_name}" : (supportMentions ? "You can mention column name using @column_name" : supportTemplateVariables && "You can use column value in the text using {column_name}")) : showHelp}
                </Text>
            </HStack>}
        </Stack >

    );
};

export default ColumnsAwareTextArea;

import { Box, Button, Checkbox, Flex, HStack, IconButton, Input, Link, Spinner, Stack, Tag, TagLabel, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import GenericList from '../GenericList/genericList';
import { AutoUI, ChatContainer, ChatMessage, GeniouslyThemeProvider, AutoDatePicker, AutosizeTextArea } from 'geniously-chat-ui';
import { useApiEndpoint, useApiFetch } from '../../utils/useApiHook';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { EditModal } from '../ModalDialogs/editModal';
import { IconArchive, IconBrandGmail, IconBrandLinkedin, IconChevronDown, IconChevronRight, IconId, IconLayoutSidebarRightCollapse, IconLayoutSidebarRightExpandFilled, IconMail, IconMailExclamation, IconMailFilled, IconPlus, IconSend } from '@tabler/icons-react';
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import DropDrown from '../DropDown/dropDown';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { IconChevronLeft } from '@tabler/icons-react';

interface Contact {
    full_name: string,
    email: string,
    linkedin_url: string,
    last_activity: string,
    waiting_for_activity: boolean,
    headline: string,
    company: string,
    position: string
    status: string
}

interface ContactStatusLabel {
    id: string,
    name: string,
    color: string,
}


const ContactCard = ({ contact: item, link, onToggleDetail: onToggleDetail, onUpdated, detailsOpen, children, allowStateChange, ...rest }: {
    contact: any, link?: string, detailsOpen?: boolean, onToggleDetail?: () => any, allowStateChange?,
    onUpdated?: (data: any) => void
    [key: string]: any
}) => {
    const settingsEndpoint = useApiFetch<{ contact_statuses: ContactStatusLabel[] }>("/org/settings", { static: true, swrOptions: { refreshInterval: 9999999, revalidateIfStale: false, revalidateOnFocus: false, revalidateOnMount: true } })
    const patchContactEndpoint = useApiEndpoint("PATCH", "/inbox/contacts/{contactId}", true, true)
    const [updating, setUpdating] = useState<string>()
    function updateStatus(status) {
        const status_id = settingsEndpoint.data?.contact_statuses.find(s => s.name == status)?.id
        if (status_id) {
            setUpdating("status")
            patchContactEndpoint.execute({ contactId: item.id }, { status: status_id }).then(() => {
                onUpdated && onUpdated({ item, status: status_id })
                setUpdating(undefined)
            })
        }
    }

    const labelSettings = useMemo(() => settingsEndpoint.data?.contact_statuses?.find(s => s.id == item.status), [settingsEndpoint.data?.contact_statuses, item.status])
    return <Stack {...rest} border="1px solid" borderRadius={6} p="4px 8px" spacing={0} fontWeight={500}>
        <HStack justify="space-between">
            {link ? (<Link href={link}>{item.full_name}</Link>) : <Text fontSize="sm" fontWeight={700}>{item.full_name}</Text>}

            <HStack>
                {item.email &&
                    <Tooltip label={<>{item.email}<br />Click to copy</>}>
                        <IconButton size="xs" aria-label="LinkedIn" p="0px" icon={<IconMail color="#0f0f0f" size="20px" />} onClick={() =>
                            navigator.clipboard.writeText(item.email)
                        } />
                    </Tooltip>
                }
                {item.linkedin_url && <Tooltip label={<><Text>{item.linkedin_url}</Text><br />Click to view profile</>}>
                    <IconButton size="xs" aria-label="LinkedIn" p="0px" icon={<IconBrandLinkedin color="blue" size="20px" />} onClick={() => window.open(item.linkedin_url)} /></Tooltip>}
                {onToggleDetail &&
                    <Tooltip label={detailsOpen ? "Hide details" : "Show details"}>
                        <IconButton size="xs" aria-label="Details" p="0px" icon={!detailsOpen ? <IconLayoutSidebarRightExpandFilled /> : <IconLayoutSidebarRightCollapse size="20px" />} onClick={onToggleDetail} />
                    </Tooltip>
                }
            </HStack>
        </HStack>
        <HStack>
        <Tooltip label={<Moment>{item.last_activity}</Moment>}><Text as={"span"} fontSize="xs">{<Moment fromNow>{item.last_activity}</Moment>}</Text></Tooltip>
            {item.waiting_for_activity && <IconMailExclamation size="17px" />}
        </HStack>
        <Text fontSize="xs">{item.headline}</Text>

        {item.company && !(item.headline && item.headline.includes(item.position) && item.headline.includes(item.company)) && <Text fontSize="xs">{item.position} at {item.company}</Text>}
        {children}
        {(item.status || allowStateChange) && (
            allowStateChange ?
                (<HStack>
                    <DropDrown isLoading={updating == "status" ? true : undefined} value={labelSettings?.name || item.status} options={settingsEndpoint.data?.contact_statuses.map(l => l.name)} onSelect={(e) => updateStatus(e)}
                        backgroundColor={item.status ? labelSettings?.color || "lightgray" : undefined} fontSize="xs" size="xs" placeholder='Lead state'
                    />
                </HStack>
                )
                : (
                    <Tag size="sm" alignSelf="start" backgroundColor={labelSettings?.color}>{labelSettings?.name || item.status}</Tag>
                )
        )}
    </Stack>
}

export default ContactCard
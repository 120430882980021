import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ListContextProps {
    list_data: any[];
    columns: { name: string, type: "string" | "enum" | "array" }[];

}

const ListContext = createContext<ListContextProps | undefined>(undefined);

export const ListProvider: React.FC<{ list_data, columns, children: ReactNode }> = ({ children, list_data, columns }) => {
    const [state, setState] = useState<ListContextProps>({ list_data, columns });


    return (
        <ListContext.Provider value={{ list_data, columns }}>
            {children}
        </ListContext.Provider>
    );
};

export const useListContext = (): ListContextProps => {
    const context = useContext(ListContext);
    if (!context) {
        throw new Error('useListContext must be used within a ListProvider');
    }
    return context;
};

import { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, CardBody, CardFooter, CardHeader, ChakraProvider, Divider, Flex, HStack, Heading, IconButton, Image, Portal, SimpleGrid, Spinner, Stack, Switch, Tag, TagLabel, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppContext } from "../appContext";

import "./homePage.css"

import { AutoUI } from "../components/AutoUI/AutoUI";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { IconWindowMaximize } from "@tabler/icons-react";
import { SearchFilter } from "../components/SearchFilter/searchFilter";
import { IconPlayerPlay } from "@tabler/icons-react";
import { IconTablePlus } from "@tabler/icons-react";
import { LuImport } from "react-icons/lu";

const ListOpsDialog = ({ onOk, onCancel }: { onOk: () => void, onCancel: () => void }) => {
  const [windowState, setWindowState] = useState<"normal" | "minimized" | "maximized">("normal")
  const [searchQuery, setSearchQuery] = useState<string>("")
  const categories = [
    "Popular",
    "Data enrichment",
    "Data manipulation",
    "Import sources"
  ]
  const [selectedCategory, setSelectedCategory] = useState<string>("Popular")

  const ops = [
    { name: "Get person info", category: "add_column", description: "Find info about company such as their linkedin / crunchbase profile and more", op_type: "data_enrichment", op_category_labels: ["Popular", "Recent"] },
    { name: "Get company info", description: "Find info about company such as their linkedin / crunchbase profile and more", op_type: "data_enrichment", op_category_labels: ["Popular", "Data enrichment"] },
    { name: "Modify column values", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from linkedin", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },
    { name: "Import from file", category: "import", description: "Execute modification operation on a single column in bulk", op_type: "data_enrichment", op_category_labels: ["Popular", "Data manipulation"] },

  ]

  function getOpIcon(op: { category: string, icon_id?: string } | any) {
    if (op.icon_id) {
      return <Image src={`https://icon-library.com/images/${op.icon_id}.png`} width="30px" height="30px" />
    }
    else if (op.category == "import") {
      return <LuImport size="30px" />
    }
    else if (op.category == "add_column") {
      return <IconTablePlus size="30px" />
    }
    else {
      return <IconPlayerPlay size="30px" />
    }
  }

  function renderOpCard(opInfo: {
    name: string
    op_type: string,
    description: string
  }) {
    return <Card p="10px" m="10px" borderRadius="8px" boxShadow="md" cursor="pointer" onClick={() => { }} maxW="300px" border="1px solid lightgray" _hover={{ shadow: "xl" }} height="180px">
      <CardHeader p="8px" >
        <HStack align="end">

          {getOpIcon(opInfo)}
          <Text fontWeight={600}>{opInfo.name}</Text>
        </HStack>
      </CardHeader>
      <CardBody p="8px 14px" alignContent="space-between" height="100%">
        <Stack justify="space-between" align="start" height="100%">
          <Text fontSize="xs" noOfLines={3}>{opInfo.description}</Text>
          <Button size="sm" >Apply</Button>
        </Stack>
      </CardBody>

    </Card>
  }
  function filterOps(op: { name: string, op_category_labels: string[] }, filterByCategory: boolean = true) {

    if (searchQuery?.length && !op.name.toLowerCase().includes(searchQuery.toLowerCase()))
      return false
    if (filterByCategory && selectedCategory && !op.op_category_labels?.includes(selectedCategory))
      return false
    return true
  }
  const filteredByCategory = useMemo(() => ops.filter(op => filterOps(op, true)), [selectedCategory, searchQuery])
  const filteredByQuery = useMemo(() => ops.filter(op => filterOps(op, false)), [selectedCategory, searchQuery])

  const categoriesWithCounts = useMemo(() => {
    return categories.map((c) => ({
      category: c,
      count: ops.filter(op => op.op_category_labels?.includes(c)).length
    }))
  }, [ops])



  return (
    <Modal isOpen={true} onClose={() => onCancel()} size="5xl">
      <ModalOverlay />
      <ModalContent>

        <ModalCloseButton />
        <ModalBody>
          <Stack >
            <HStack justify="space-between">
              <Text mr="-100px" fontWeight={900}>Available actions</Text>
              <HStack justify="stretch" justifySelf="center" alignSelf="center" maxW="400px" flexGrow={1} width="100%" p="20px">
                <SearchFilter flexGrow={1} autoApply placeholder="Search in available actions" autoApplyDelay={300} onApply={setSearchQuery} />
              </HStack>
              <Box ></Box>
            </HStack>
            {/* <Text>

              
            </Text> */}
            <HStack align="stretch" spacing={0}>
              <Stack className="ops-side-options" width="200px" height="100%" >
                {categoriesWithCounts.map((c) => (
                  <HStack _hover={{ backgroundColor: "#fafafa", fontWeight: 600, p: "4px 4px" }}
                    m="0px 4px" p="4px 8px" borderRadius={8} cursor="pointer" whiteSpace="nowrap"
                    onClick={() => setSelectedCategory(c.category)}

                    fontWeight={c.category == selectedCategory ? 600 : undefined} backgroundColor={c.category == selectedCategory ? "gray.100" : undefined}
                  >
                    <Text>{c.category}</Text>
                    <Tag>
                      <TagLabel>{c.count}</TagLabel>
                    </Tag>

                  </HStack>

                ))}


              </Stack>
              <Stack className="ops-mainPanel" backgroundColor="gray.100" borderRadius={10} flexGrow={1} p="10px" height="65vh" overflow="auto" align="center">
                {/* <Text>{JSON.stringify(selectedCategory)}</Text> */}


                <Wrap>
                  {filteredByCategory?.map((op) => (renderOpCard(op)))}
                  {(filteredByCategory?.length == 0 && searchQuery && filteredByQuery?.length && (
                    <Stack>
                      <Stack align="center" spacing={0} m="10px">

                        <Text>No results found in {selectedCategory} category...</Text>
                        <Text>Search results for other categories:</Text>
                      </Stack>
                      {filteredByQuery?.map((op) => (renderOpCard(op)))}
                    </Stack>
                  ))}
                </Wrap>

              </Stack>
            </HStack>

          </Stack>


        </ModalBody>

        {/* <ModalFooter>

          <Button onClick={onCancel}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  )


}


export default function Test() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])


    function renderChat(active?:boolean){
      if (!active) 
          return  <></>
      else    {
          let wssUrl = process.env.REACT_APP_API_URL;
          let appId="899cbe3c-d646-4d2a-9974-6f31c5ceacc9"
          wssUrl = wssUrl.replace("http","ws")
          return (
             <></>
              // <ChatPopover
              //     triggerButton={<ChatFloatingButton/>}
              // >
              // <ChatComponent wsEndpoint={`${wssUrl}/${appId}/chat`}
              // messages={[
              //     {
              //         sentByMe: true,
              //         message: "Hi, I am a bot. I can help you with your queries",
              //         type:"chat"
              //     },
              //     {
              //       sentByMe: false,
              //       message: "Hi, certainkly, Here [^1] is an example of a chat message: ```\del()\```,\n[^1]: Big note.* [ ] to do\n* [x] done",
              //       type:"chat"
              //   },
              // ]}
              // />
              
              // </ ChatPopover>
          )
      }
     }
    const [value, setValue] = useState<any>({})
  const [modal, setModal] = useState<JSX.Element | null>(null)

    return (
        <Box  >
        <ListOpsDialog onOk={() => { }} onCancel={() => {
          setModal(null)
        }} />
          
          <AutoUI 
          value={value}
           onValueChange={setValue}
            schema={
                {
                    "title": "DataSourceDefinition",
                    "type": "object",
                    "properties": {
                      "name": {
                        "title": "Name",
                        "description": "Name of the datasource, that would help AI to figure out when to use it",
                        "maxLength": 256,
                        "example": "MyApp documentation",
                        "type": "string"
                      },
                      "description": {
                        "title": "Description",
                        "description": "Detailed description when to use the datasource, that would help AI and how to use it",
                        "maxLength": 1024,
                        "example": "Useful when user asks about how to do something in the MyApp",
                        "type": "string"
                      },
                      "type": {
                        "title": "Type",
                        "description": "Type of the implementation",
                        "maxLength": 256,
                        "type": "string"
                      },
                      "configuration": {
                        "title": "PlainTextDataSourceConfiguration",
                        "type": "object",
                        "properties": {
                          "content_text": {
                            "title": "Content Text",
                            "description": "Text information to use as a reference source of data",
                            "maxLength": 100000,
                            "type": "string"
                          },
                          "content_format": {
                            "title": "Content Format",
                            "description": "Format of the content",
                            "default": "plain_text",
                            "example": "plain",
                            "one_of": [
                              "plain_text",
                              "markdown"
                            ],
                            "type": "string"
                          }
                        }
                      }
                    },
                    "required": [
                      "name",
                      "description",
                      "type",
                      "configuration"
                    ],
                    "definitions": {
                      "BaseModel": {
                        "title": "BaseModel",
                        "type": "object",
                        "properties": {}
                      }
                    }
                  }
                
            }
            />
        </Box>
    )
}

import { Box, Button, HStack, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, Stack, Text, useDisclosure } from "@chakra-ui/react"

import { useEffect, useMemo, useState } from "react"
import { useAppContext } from "../../appContext"
import { useParams } from "react-router-dom"
import { getApi } from "../../apiService"
import useSWR from 'swr'
import { IconPlus } from "@tabler/icons-react"

const AppSelect= ({}:{
 
}) => {

const {appId} = useParams()
const {currentUser, currentAppProject, setCurrentAppProject} = useAppContext()
const { isOpen, onToggle, onClose } = useDisclosure()

const {data:allAps,  mutate: reloadApps} = useSWR(`apps/${isOpen}`,()=>{
    if (isOpen){
        return getApi().getAllApps()
    }
    else{
        return null
    }
})

const [isLoading,setIsLoading]=useState(false)
useEffect(()=>{
    const _selectedAppId = appId || currentUser?.default_app_id
    if ((_selectedAppId) && !currentAppProject){
        setIsLoading(true)
        getApi().getApp(_selectedAppId).then((app)=>{
            setCurrentAppProject(app)
            setIsLoading(false)
        })
    }
},[appId, currentUser])



return (<Popover
  isOpen={isOpen}
  onClose={()=>{
    
    
      onClose()
    }}
>
  <PopoverTrigger

  >
    <Button   onClick={onToggle} variant='solid' size="xs"  border="1px white solid" boxShadow="outline" m="5px 10px"  >
      
    {isLoading ? <HStack><Spinner size="xs"/><Text>Loading...</Text></HStack> :
    <Text maxW="140px"  textOverflow="ellipsis" overflow="hidden">{currentAppProject?.name || "Select assistant"}</Text>}
        
      </Button>
  </PopoverTrigger>
  <PopoverContent width="auto" background="white" p={1} boxShadow="lg">
    <PopoverArrow />
    <Stack direction="column" spacing="1" align="stretch">
        {allAps?.filter(a=>a.id!==currentAppProject?.id).map((app,i)=>(
            <Button  key={i} size="xs" width="100%" justifyContent="start" variant="outline" 
            onClick={()=>{
                setCurrentAppProject(app)
                onClose()
            }}
            >
              {app.name} 
              </Button>
        ))
        }
      {/* <Button size="xs" width="100%" justifyContent="start" variant="outline" colorScheme="orange" leftIcon={<IconPlus size="15px"/>} isDisabled
            onClick={()=>{
                //onClose()
            }}
      >New workspace</Button> */}
    

          <Button size="xs" onClick={()=>{
            
            onClose()
          }
            }>Cancel</Button>
          
    {/* <PopoverBody>
        </PopoverBody> */}
    </Stack>
  </PopoverContent>
</Popover>)
}

export default AppSelect
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';

type OptionsSwitchProps<T extends string> = {
    options: string[],
    value?: string,
    size?: 'xs' | 'sm' | 'md' | 'lg',
    colorScheme?: string,
    onChange?: (option: any) => void
    [key: string]: any
};

const OptionsSwitch = <T extends string>({ options, value, size = 'xs', colorScheme = 'brand', onChange, ...rest }: OptionsSwitchProps<T>) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(value);

    useEffect(() => {
        if (value !== selectedOption) {
            setSelectedOption(value);
        }
    }, [value]);

    const handleOptionChange = (option: string) => {
        setSelectedOption(option);
        if (onChange) {
            onChange(option);
        }
    };

    return (
        <ButtonGroup size={size} colorScheme={colorScheme} isAttached {...rest}>
            {options.map((option) => (
                <Button
                    key={option}
                    variant={selectedOption === option ? 'solid' : 'outline'}
                    onClick={() => handleOptionChange(option)}
                >
                    {option}
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default OptionsSwitch;

import { Box, Button, Checkbox, Editable, EditableInput, EditablePreview, Flex, HStack, IconButton, Input, Spinner, Stack, Tag, TagLabel, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import GenericList from '../GenericList/genericList';
import { AutoUI, ChatContainer, ChatMessage, GeniouslyThemeProvider, AutoDatePicker, AutosizeTextArea } from 'geniously-chat-ui';
import { useApiEndpoint, useApiFetch } from '../../utils/useApiHook';
import { useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { EditModal } from '../ModalDialogs/editModal';
import { IconAlertHexagon, IconAlertHexagonFilled, IconAlertSquareRoundedFilled, IconArchive, IconBrandGmail, IconBrandLinkedin, IconBuilding, IconChevronDown, IconChevronRight, IconExclamationCircle, IconGlobe, IconHelpOctagon, IconId, IconLayoutSidebarRightCollapse, IconLayoutSidebarRightExpandFilled, IconMail, IconMailExclamation, IconMailFilled, IconPhone, IconPlus, IconRosetteFilled, IconSend, IconWorldWww } from '@tabler/icons-react';
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { IconChevronLeft } from '@tabler/icons-react';
import { EditableField } from '../EditableField/editableField';
import { IconShieldCheckFilled } from '@tabler/icons-react';
import { IconCircleDashedX } from '@tabler/icons-react';

interface Contact {
    id: string,
    full_name: string,
    email: string,
    email_verified: boolean | undefined,
    linkedin_url: string,
    phone: string,
    last_activity: string,
    waiting_for_activity: boolean,
    headline: string,
    position: string,
    company: string
    company_url: string
    extra: {
        [key: string]: any
    }
}
const removeUndefinedOrNull = (obj: any) => {


    return obj && Object.keys(obj).sort().reduce((acc, key) => {
        if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
            acc[key] = obj[key];
        }
        return acc;
    }, {} as any);
};

const ContactDetail = ({ contact: item, onUpdated, onChange }: {
    contact: any,
    onUpdated?: (contact: any) => any,
    onChange?: (contact: any) => any
}) => {
    const [contactValue, _setContactValue] = useState<Contact>()
    const setContactValue = (val: Contact) => {
        _setContactValue(val)
        onChange && onChange(val)
    }

    const isChanged = useMemo(() => {
        return onUpdated && JSON.stringify(removeUndefinedOrNull(item)) !== JSON.stringify(removeUndefinedOrNull(contactValue))
    }, [contactValue])
    useEffect(() => {
        setContactValue(item)
    }, [item])

    const validationError = useMemo(() => {
        if (!contactValue?.full_name) {
            return "Name is required"
        }
    }, [contactValue])

    const updateContact = useApiEndpoint("PATCH", `/inbox/contacts/{id}`)

    return !contactValue ? (<></>) : <Stack borderRadius={6} p="4px 8px" spacing={0} fontWeight={500} height="100%" flexGrow={1}>

        <EditableField required value={contactValue?.full_name} placeholder='<contact full name>' fontSize="sm" fontWeight={900} onApply={(val) => setContactValue({ ...contactValue, full_name: val })} />
        <EditableField value={contactValue?.headline} placeholder='headline' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, headline: val })} />

        <HStack>
            {contactValue.linkedin_url ? <Tooltip label={<><Text>{contactValue.linkedin_url}</Text><br />Click to view profile</>}><IconButton size="xs" ml="-3px" aria-label="LinkedIn" p="0px" icon={<IconBrandLinkedin color="blue" size="20px" />}
                onClick={() => window.open(contactValue.linkedin_url)} /></Tooltip> : (
                <IconBrandLinkedin color="gray" size="20px" />
            )}
            <EditableField value={contactValue?.linkedin_url} placeholder='LinkedIn URL' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, linkedin_url: val })} />
        </HStack>

        <HStack>
            {contactValue.email ?
                <Tooltip label={<>{contactValue.email}<br />Click to copy</>}>
                    <IconButton ml="-3px" size="xs" aria-label="EMail" p="0px" icon={<IconMail color="#0f0f0f" size="20px" />} onClick={() =>
                        navigator.clipboard.writeText(contactValue.email)
                    } />
                </Tooltip> : (
                    <IconMail color="gray" size="18px" />
                )
            }
            <EditableField value={contactValue?.email} placeholder='email@company.com' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, email: val })} />
            <Tooltip>

                <IconButton size="xs" onClick={() => {
                    if (contactValue?.email_verified == true) {
                        setContactValue({ ...contactValue, email_verified: false })

                    }
                    else if (contactValue?.email_verified == false) {
                        setContactValue({ ...contactValue, email_verified: undefined })

                    }
                    else {
                        setContactValue({ ...contactValue, email_verified: true })
                    }
                }} aria-label="Verified" p="0px" color="limegreen" variant="ghost" icon={
                    contactValue?.email_verified ?
                        <IconShieldCheckFilled color="rgb(102 161 102)" size="20px" /> :
                        (
                            (contactValue?.email_verified as any) === false ?
                                <IconExclamationCircle color="tomato" size="20px" /> :
                                <IconHelpOctagon color="gray" size="20px" />)} />
            </Tooltip>
        </HStack>
        <HStack>
            {contactValue.phone ?
                <Tooltip label={<>{contactValue.phone}<br />Click to copy</>}>
                    <IconButton ml="-3px" size="xs" aria-label="Phone" p="0px" icon={<IconPhone color="#0f0f0f" size="20px" />} onClick={() =>
                        navigator.clipboard.writeText(contactValue.phone)
                    } />
                </Tooltip> : (
                    <IconPhone color="gray" size="18px" />
                )
            }
            <EditableField value={contactValue?.phone} placeholder='phone' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, phone: val })} />

        </HStack>



        <HStack>
            <IconBuilding color="gray" size="18px" />
            <EditableField value={contactValue?.company} placeholder='company' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, company: val })} />

        </HStack>
        <HStack spacing={0}>
            <Text fontSize="12px" fontWeight={300} ml="12px" mr="4px">as</Text>
            <EditableField value={contactValue?.position} placeholder='position' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, position: val })} />

        </HStack>
        <HStack>
            {contactValue.company_url ? <Tooltip label={<><Text>{contactValue.company_url}</Text><br />Click to open</>}><IconButton size="xs" ml="-3px" aria-label="company website" p="0px" icon={<IconWorldWww color="blue" size="18px" />}
                onClick={() => window.open(contactValue.company_url)} /></Tooltip> : (
                <IconWorldWww color="gray" size="18px" />
            )}
            <EditableField value={contactValue?.company_url} placeholder='company url' fontSize="xs" onApply={(val) => setContactValue({ ...contactValue, company_url: val })} />
        </HStack>



        <Stack p="10px"></Stack>
        {validationError && <Text color="#8f0a0a" fontSize="xs">{validationError}</Text>}
        {isChanged && onUpdated && (<HStack bottom="0px" position="sticky">
            <Button isDisabled={!!validationError} isLoading={updateContact.isRunning} size="xs" colorScheme="brand" onClick={() =>
                updateContact.execute({ id: item.id }, contactValue).then((res) => {
                    if (res) {
                        setContactValue(res)
                    }

                    onUpdated && onUpdated(contactValue)
                })
            }>Save</Button>
            <Button size="xs" colorScheme="gray" onClick={() => setContactValue(item)}>Cancel</Button>

        </HStack>)}
    </Stack>
}

export default ContactDetail


import { useEffect, useMemo, useState } from "react";
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link as ReactRouterLink } from 'react-router-dom'
import useSWR, { mutate } from 'swr'

import { useAppContext } from "../appContext";
import { Badge, Box, Button, Card, CardBody, CardFooter, CardHeader, Checkbox, Flex, HStack, Heading, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spinner, Stack, Tag, Text, Tooltip, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { AppSidebar, AppSidebarButton } from "../components/AppSidebar/appSidebar";
import { IconCreditCard, IconDots, IconQuestionMark, IconSend, IconUserPlus } from "@tabler/icons-react";
import Pricing from "../components/Pricing/pricing";
import { AutoUI } from "../components/AutoUI/AutoUI";
import Moment from "react-moment";
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements,
    AddressElement,
  } from '@stripe/react-stripe-js';
import { TbLock } from "react-icons/tb";
import { useApiEndpoint } from "../utils/useApiHook";
import { EditModal } from "../components/ModalDialogs/editModal";
import { ConfirmModal } from "../components/ModalDialogs/confirmModal";

export  default function AccountSettings(){
    
    const {currentUser, logOut, setError} = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const {settingsSection}=useParams()

   useEffect(()=>{
    if (searchParams.get("checkout_session_id")){
        getApi().checkoutSessionFinish(searchParams.get("checkout_session_id")).then((res)=>{
            window.location.href = window.location.origin + window.location.pathname;
        }).catch((e)=>{
            setError(e.message,"Finishing checkout")
        })
    }
   },[searchParams])
   

    return ( <Flex direction="column" p="10px" width="100%">
     
     {settingsSection=="my-account"&&(
            <MyAccount/>

     )}
     {settingsSection=="manage-plan"&&(
            <Pricing/>

     )}
     {settingsSection=="billing"&&(
            <Billing/>

     )}
     {settingsSection=="team"&&(
            <Team/>

     )}
    </Flex>
    
    )
}


const Billing=()=>{

    const loadInvoices = ()=>{
        return getApi().getInvoices()

    }
    const loadUpcomingInvoice = ()=>{
        return getApi().getUpcomingInvoice()

    }
    const {data:upcomingInvoice, isLoading:isLoadingUpcoming} = useSWR(`upcomingInvoice`,()=>loadUpcomingInvoice())
    const {data:invoices, isLoading:isLoadingInvoices} = useSWR(`recentInvoices`,()=>loadInvoices())
   
    const [modal, setModal ] = useState<JSX.Element|null>(null)
    function downloadFile(file_url) {
        // Create a link and set the URL using `createObjectURL`
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = file_url
        link.download = "invoice.pdf";
      
        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();
      
        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
          URL.revokeObjectURL(link.href);
          link.parentNode.removeChild(link);
        }, 10);
      }

    return (
        <VStack align="start">
            {modal}
          {(isLoadingUpcoming)?(<Spinner/>): <Card p="20px" width="100%">
            <HStack justify="space-between">
                <Heading size="sm"  fontWeight={500}>Upcoming invoice</Heading>
                <Button size="xs" variant="outline" colorScheme="brand"
                 onClick={()=>setModal( <BillingInfo onClose={()=>setModal(undefined)}/>)}>
                    Change billing info</Button>
            </HStack>
            <Text fontSize="md" color="gray">{upcomingInvoice?.total} {upcomingInvoice?.currency?.toUpperCase()}</Text>
            <Text fontSize="sm" fontWeight={500}>Period</Text>
            {upcomingInvoice&&
            (<HStack fontSize="xs" color="gray">
                <Moment format="DD MMM YYYY">{upcomingInvoice.period_start}</Moment>
                <Text>-</Text>
                <Moment format="DD MMM YYYY">{upcomingInvoice.period_end}</Moment>
            </HStack>
            )
        }
            {upcomingInvoice?.lines?.length &&
            <VStack align="start">
                <Text fontSize="xs">Details</Text>
                <VStack p="0px 15px" width="400px" align="stretch">
                {upcomingInvoice.lines.map((l,i)=>(
                    <HStack  key={i} fontSize="xs" fontWeight={500} justify="space-between">
                        <Text>{l.description}</Text>
                        <Text>{l.amount} {upcomingInvoice.currency?.toUpperCase()}</Text>
                    </HStack>
                ))}
                </VStack>
            </VStack>
            }
          </Card>}
          <Card p="20px" width="100%">
          {(isLoadingInvoices)?(<Spinner/>): 
          <VStack align="start">
            <Heading size="sm">Previous invoices</Heading>
            {!invoices?.length && (
                <Box p="20px">
                    <Text color="lightgray">No previous invoices</Text>
                </Box>
            )}
            {invoices?.map((invoice,i)=>(
                <HStack key={i} width="100%" justify="space-between" fontSize="xs" p="2px" borderRadius="2px" _hover={{backgroundColor:"gray.100"}}
                onClick={(e)=>{
                    getApi().getInvoice(invoice.id).then((res)=>{
                        downloadFile(res.url)
                    })
                }}
                >
                    <HStack>
                    <Moment format="DD MMM YYYY">{invoice.period_start}</Moment>
                    <Text>-</Text>
                    <Moment format="DD MMM YYYY">{invoice.period_end}</Moment>
                    </HStack>
                    <Text fontSize="xs">{invoice?.total} {invoice?.currency?.toUpperCase()}</Text>
                </HStack>

            ))}
            
            </VStack>
            }
          </Card>
        </VStack>
    )
}

const Team=()=>{

   
    const { data: accounts, isLoading: isLoadingAccounts, mutate: mutateAccounts } = useSWR(`accounts`, () => getApi().getAccounts())
    const {data:invites, isLoading:isLoadingInvites} = useSWR(`invites`,()=>getApi().getInvites())
    const {currentUser} = useAppContext()

    const [modal, setModal ] = useState<JSX.Element|null>(null)
    const [inviteHover, setInviteHover]=useState<string>()
    const [revoking, setRevoking]=useState<string>()
    const resetPasswordEndpoint = useApiEndpoint("POST", "/org/password-reset")
    const updateUserEndpoint = useApiEndpoint("PATCH", "/org/accounts/{account_id}")
    return (
        <VStack align="stretch" width="100%" >
            <Text>Team</Text>
           {modal}
          <Card p="20px" width="100%">

          {(isLoadingAccounts)?(<Spinner/>): 
                    <VStack align="start" alignSelf="center" border="1px solid lightgray" borderRadius={8} p="10px" spacing={5}>
                        <HStack justify="space-between" width="100%" >

            <Heading size="sm">Accounts</Heading>

                            <Button size="xs" colorScheme="brand" leftIcon={<IconUserPlus size="15px" />}
                                onClick={() => {

                                    setModal(<InviteModal onClose={() => {
                                        setModal(undefined)
                                        mutate(`invites`)
                                    }} />)
                                }}
                            >Add a team mate</Button>
                        </HStack>
                        <Stack p="10px">
            {accounts?.map((account,i)=>(
                <HStack key={i} width="100%" justify="space-between" spacing="20vw" _hover={{ background: "gray.50" }}>
                    <HStack flexGrow={1} >
                    <Text fontSize="xs">{account.name}</Text>
                        {currentUser?.tenant_id == "1" && <Tag colorScheme="gray" color="gray" fontSize="xs">tenant: {account.tenant_id}</Tag>}
                    </HStack>
                    <HStack alignSelf="end" >
                        {/* <Button size="xs" colorScheme="brand" flexGrow={1} variant="outline" isLoading={resetPasswordEndpoint.isRunning} onClick={() => {
                            resetPasswordEndpoint.execute({}, null, { email: account.email })
                        }}>Reset password</Button> */}
                        <HStack>
                            <Text fontSize="xs" fontWeight={500}>Role:</Text>
                    {currentUser?.id!=account.id?(
                                <Select size="xs" value={account.role} onChange={(e) => {
                                    updateUserEndpoint.execute({ account_id: account.id }, { role: e.target.value }).then(() => {
                                        mutate("accounts")
                                    })
                                }}>
                            <option value='admin'>admin</option>
                            <option value='poweruser'>power user</option>
                            <option value='readonly'>readonly</option>
                        </Select>

                    ):(
                            <Tooltip label="You can't edit your own role" placement="right-start" >
                        <Flex>
                            <Text fontSize="xs">{account.role}</Text>
                                <TbLock/>
                        </Flex>
                            </Tooltip>
                        )}
                            <Menu>
                                <MenuButton as={IconButton} icon={<IconDots />} size="xs" colorScheme="brand" variant="outline">More</MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => {
                                        setModal(<ConfirmModal
                                            caption="Reset password"
                                            question={`Are you sure you want to reset the password? Password reset link will be sent to ${account.email}`}
                                            onOk={() => {
                                                setModal(undefined)
                                                resetPasswordEndpoint.execute({}, null, { email: account.email })
                                            }}
                                            onCancel={() => setModal(undefined)}
                                        />)
                                    }}>Reset password</MenuItem>
                                    <MenuItem color="tomato" onClick={() => {
                                        setModal(<ConfirmModal
                                            caption="Deactivate account"
                                            question={`Are you sure you want to deactivate ${account.name}?`}
                                            onOk={() => {
                                                updateUserEndpoint.execute({ account_id: account.id }, { active: false }).then(() => {
                                                    mutate("accounts")
                                                    setModal(undefined)
                                                })
                                            }}
                                            onCancel={() => setModal(undefined)}
                                        />)
                                    }}>Deactivate</MenuItem>
                                    {currentUser?.tenant_id === "1" && <MenuItem onClick={() => {
                                        setModal(<EditModal
                                            caption="Move to tenant"

                                            value={{}}
                                            onCancel={() => setModal(undefined)}
                                            onOk={(val) => {
                                                updateUserEndpoint.execute({ account_id: account.id }, val).then(() => {
                                                    mutate("accounts")
                                                    setModal(undefined)
                                                })
                                            }}>
                                            {(val, setVal) => (
                                                <AutoUI value={val}
                                                    onValueChange={setVal}
                                                    schema={{
                                                        properties: {
                                                            update_tenant_id: { type: "string", title: "New Tenant ID" }
                                                        }
                                                    }}
                                                />)
                                            }
                                        </EditModal>)
                                    }}>Change tenant</MenuItem>}
                                </MenuList>
                            </Menu>
                        </HStack>
                    </HStack>
                    
                </HStack>

            ))}
                        </Stack>

            {invites?.filter(t=>t.tenant_id==t.sent_by_tenant).map((invite,i)=>(
                <HStack key={i} width="100%" justify="space-between" color="gray.500">
                    <HStack>
                    <Text fontSize="xs">{invite.email}</Text>
                    <Badge colorScheme="gray" fontSize="xs">Pending</Badge>
                        {<Button size="xs" colorScheme="red" variant="outline"
                    loadingText="Revoking"
                    isLoading={revoking==invite.id}
                    onClick={()=>{
                        setRevoking(invite.id)
                        getApi().revokeInvite(invite.id).then(()=>{
                            setRevoking(undefined)
                            mutate(`invites`)
                        }).catch(()=>{
                                setRevoking(undefined)
                            })
                        }
                        }
                    >Revoke</Button>}

                    </HStack>

                        <HStack alignSelf="end">
                            <Text fontSize="xs" fontWeight={500}>Role:</Text>
                    
                            <Text fontSize="xs">{invite.role}</Text>
                    </HStack>
                    
                </HStack>

            ))}
                <VStack width="100%" align="end">

                </VStack>
            </VStack>
            }
          </Card>
          <Box p="20px">
          {invites?.filter(t=>t.tenant_id!=t.sent_by_tenant).map((invite,i)=>(
                <HStack key={i} width="100%" justify="space-between" onMouseEnter={()=>setInviteHover(invite.id)} onMouseLeave={()=>setInviteHover(undefined)} color="gray.500">
                    <HStack>
                    <Text fontSize="xs">{invite.email}</Text>
                    <Badge colorScheme="orange" fontSize="xs">External</Badge>
                    <Badge colorScheme="gray" fontSize="xs">Pending</Badge>
                    {(invite.id == inviteHover || invite.id==revoking )&&<Button size="xs"  colorScheme="red"
                    loadingText="Revoking"
                    isLoading={revoking==invite.id}
                    onClick={()=>{
                        setRevoking(invite.id)
                        getApi().revokeInvite(invite.id).then(()=>{
                            setRevoking(invite.id)
                            mutate(`invites`)})}}
                    >Revoke</Button>}
                    </HStack>

                        
                    
                </HStack>

        ))}
        </Box>

    
        </VStack>
    )
}


const InviteModal=({onClose}:{onClose:()=>any})=>{

    const [email, setEmail] = useState<string>("")
    const [role, setRole] = useState<string>("admin")
    const [new_tenant, setNewTenant] = useState(false)
    const [error, setError] = useState<string>("")
    const [sending, setSending] = useState(false)
    function sendInvite(){
        if (!email){
            setError("Please enter an email")
            return
        }
        //validate email
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)){
            setError("Please enter a valid email")
            return
        }
        setSending(true)
        getApi().createInvite({
            email,
            role:(new_tenant?"admin":role), 
            new_tenant
            
        }).then(()=>{
            onClose()
        }).catch((e)=>{
            setSending(false)
            setError(e.message)
        })
    }

    const {currentUser} = useAppContext()
    const hasSameEmailDomain = useMemo(()=>{
        if (currentUser?.email && email && currentUser?.email.includes("@") && email.includes("@")){
            return currentUser.email.split("@")[1]==email.split("@")[1]
        }
    },[email,currentUser])

    return <Modal 
        autoFocus={false}
        size="5xl"
        
        isOpen={true} onClose={() => {
            onClose();
    }}>
        <ModalOverlay />
        <ModalContent width="80vw" >
          
            <ModalHeader>Create invite</ModalHeader>
            <ModalBody
              maxH="60vh"  overflow="auto">
                <Box>
                <HStack justify="space-between" marginEnd="15px">

                <Text>Enter the email of the team mate you want to add</Text>
                <Text>Role</Text>
                </HStack>
                <HStack>

                    <Input size="sm" type="email" placeholder="Email" value={email} onChange={e=>setEmail(e.target.value)}/>
                    <Select size="sm" value={!new_tenant?"admin":role} disabled={new_tenant}   onChange={(e)=>{
                        setRole(e.target.value)
                        }} width="200px">
                            <option value='admin'>admin</option>
                            <option value='poweruser'>power user</option>
                            <option value='readonly'>readonly</option>
                    </Select>
                </HStack>
                <Flex justify="start">

                <Tooltip label="If not checked, the invite will not be attached to your organization and after signing up, the user will be considered as new customer.">
                <HStack spacing={0} align="start" marginTop={2}>
                    <Checkbox isChecked={!new_tenant} onChange={e=>setNewTenant(!e.target.checked)} size="sm" colorScheme="brand">Invite to join my team </Checkbox>
                    <IconQuestionMark display="inline" size="12px"/>
                </HStack>
                </Tooltip>
                </Flex>
                {hasSameEmailDomain!==undefined && !new_tenant&& !hasSameEmailDomain && (
                        <Text fontSize="xs" fontWeight={900}>Warning! - the invitee's email has different email domain. Are you sure you want to invite this person to share access to all of your your data?</Text>
                )}
                {/* {new_tenant&& hasSameEmailDomain && !email.includes("gmail") && (
                        <Text fontSize="xs">Note:  the invitee's email has the same email domain. Didn't you want to join him your team? </Text>
                )} */}
                <Text fontSize="sm" color="red">{error}</Text>
                </Box>
            </ModalBody>
            <ModalFooter>
          
          <HStack spacing={2}>
                <Button
                    size={"sm"}
                    variant="outline"
                    colorScheme='brand'
                    onClick={() =>onClose()}
                >
                    Cancel
                </Button>
                <Button
                    size={"sm"}
                    isLoading={sending}
                    loadingText="Sending"
                    leftIcon={<IconSend/>}
                    colorScheme='brand'
                    onClick={() =>sendInvite()}
                >
                    Send invite
                </Button>
                </HStack>
            </ModalFooter>
        </ModalContent>
    </Modal>
}



const MyAccount=()=>{

    const loadMyAccount = ()=>{
        return getApi().getMyAccount().then((res)=>{
            return res
        })

    }
    const loadTenantInfo = ()=>{
        return getApi().getMyOrganizationInfo().then((res)=>{
            return res || {"plan":null}
        })

    }
    const {data:myAccountInfo, isLoading:isLoadingMyAccountInfo} = useSWR(`myAccountInfo`,()=>loadMyAccount())
    const {data:myTenantInfo, isLoading:isMyTenantLoading} = useSWR(`myTenantInfo`,()=>loadTenantInfo())

    
    
    const [modal, setModal] = useState<JSX.Element|null>(null)
    const activateOrUpdatePlan = (plan:string=undefined)=>{
        const plan_id = plan
        if (plan_id){
            return getApi().checkoutSession(plan_id,window.location.href).then(({checkout_session_url})=>{
                window.location.href=checkout_session_url
            })
        }
        else{
            PickAPlan((plan)=>activateOrUpdatePlan(plan))
            
        }
    }

    function PickAPlan(callback:(plan:string)=>any){
        setModal(<ChoosePlanModal currentPlan={myTenantInfo?.plan} 
            onClose={()=>{setModal(undefined)}}  
            onOK={(newPlan)=>{
                setModal(undefined)
                callback(newPlan)
            }}
        />)
    }

    return (
        <VStack align="start">
            {modal}
            <Heading>My account</Heading>
            {isLoadingMyAccountInfo?(<Spinner/>):(
                <VStack align="start">
              <AutoUI value={myAccountInfo} schema={{properties:{
                name:{type:"string", title:"Name"},
                email:{type:"string", title:"Email"},
                  // role:{type:"string", title:"Role", readOnly:true},
                  // id:{type:"string", title:"ID", readOnly:true},
                  // active:{type:"boolean", title:"Active", readOnly:true},
              }}}
                        showOnlySchemaFields
              onValueChange={(newVal)=>{
                let patchPayload={}
                if (newVal?.email!=myAccountInfo?.email){
                    patchPayload["email"]=newVal?.email
                }
                if (newVal?.name!=myAccountInfo?.name){
                    patchPayload["name"]=newVal?.name
                }
                    getApi().patchAccountInfo(myAccountInfo.id,patchPayload).then(()=>{mutate(`myAccountInfo`)})
              }}
              />

                    <AutoUI value={myAccountInfo} schema={{
                        properties: {

                            role: { type: "string", title: "Role", readOnly: true },
                            id: { type: "string", title: "ID", readOnly: true },
                            active: { type: "boolean", title: "Active", readOnly: true },
                        }
                    }}
                        showOnlySchemaFields

                    />

                <HStack >

              <Button colorScheme="brand" variant="outline" size="xs" as={ReactRouterLink} to="/password-reset">Change password</Button>
              
                </HStack>
              </VStack>
            )}
            <Heading marginTop="20px" >My organization</Heading>
            {
                isMyTenantLoading?(<Spinner/>):(
                    (myTenantInfo?.plan?(
                        <VStack align="start">
                            <AutoUI 
                            readOnly
                            excludeFields={["id","active"]}
                            value={myTenantInfo} schema={{properties:{
                name:{type:"string", title:"Name"},
                plan:{type:"string", title:"Plan", readOnly:true},
                active_until:{type:"string", title:"Active until", readOnly:true},
              }}}
              
              />

                           
                    <Button colorScheme="brand" size="xs" onClick={()=>PickAPlan((plan)=>getApi().managePlan(plan))}>Change current plan</Button>
                        <Button size="xs" colorScheme="brand" 
                        variant="outline"
                        onClick={(val)=>{setModal(<BillingInfo onClose={()=>setModal(undefined)}/>)}}>
                                Change billing details</Button>
                        </VStack>
                    ):(
                        <VStack p="10px">
                            <Text fontSize="xs">No plan has been activated yet... please activate your plan first</Text>
                            <Button colorScheme="brand" onClick={()=>activateOrUpdatePlan()}>Choose a plan</Button>
                        </VStack>
                    )
                    )
                )
            }
        </VStack>
    )
}

const BillingInfo=({onClose}:{onClose:()=>any})=>{
    const [billingInfo, setBillingInfo] = useState<BillingInfo>(undefined)
    const {data:billingInfoSaved, isLoading} = useSWR(`billingInfo`,()=>getApi().getBillingInfo())
    
    
    const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

    useEffect(()=>{
        if (billingInfoSaved){
            setBillingInfo(billingInfoSaved)
            
            
        }
    },[billingInfoSaved])

    function save(){
        getApi().updateBillingInfo(billingInfo).then(()=>{
            onClose()
        })
    }

    const checkoutSession = ()=>{
        getApi().getMyOrganizationInfo().then((tenant)=>{
            if (tenant.plan){
                return getApi().checkoutSession(tenant.plan,window.location.href).then(({checkout_session_url})=>{
                    window.location.href=checkout_session_url
                })
            }
        }
        )
       
    }

    return (<Modal 
            autoFocus={false}
            size="5xl"
            
            isOpen={true} onClose={() => {
                onClose();
        }}>
            <ModalOverlay />
            <ModalContent width="80vw" >
              
                <ModalHeader>Update billing info</ModalHeader>
                <ModalBody  maxH="60vh"  overflow="auto">
                    <Box>
    
                    {!billingInfo?(<Spinner/>):(
                    <VStack align="start">
            
            <Elements stripe={stripePromise} options={{
              appearance:{rules:{
          
              }}
            }}>
              <Text>Business name</Text>
            <Input  width="292px" placeholder="Business name" value={billingInfo?.name||""}
              onChange={e=>setBillingInfo({...(billingInfo||{}) ,name:e.target.value})}
              />
              <AddressElement options={{mode:"billing",
              
           defaultValues: {
              name: billingInfo?.additional_fields?.person_name,
              address: {
                line1: billingInfo?.address_1,
                line2: billingInfo?.address_2,
                city: billingInfo?.city,
                state: billingInfo?.state,
                postal_code: billingInfo?.zip,
                country: billingInfo?.country,
              },    
          }}} onChange={(e)=>{
                  setBillingInfo({
                      name:billingInfo?.name,
                      additional_fields:{"person_name":e.value.name},
                      address_1:e.value.address?.line1,
                      address_2:e.value.address?.line2,
                      city:e.value.address?.city,
                      state:e.value.address?.state,
                      zip:e.value.address?.postal_code,
                      country:e.value.address?.country,
                      vat:billingInfo?.vat
                  })
              }}
              />
              <Text>VAT</Text>
              <Input  width="292px" placeholder="Business name" value={billingInfo?.vat||""}
              onChange={e=>setBillingInfo({...(billingInfo||{}) ,vat:e.target.value})}
              />
            </Elements>
          
                  </VStack>
                    )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <HStack justify="space-between" width="100%">
                    <Button
                        size={"sm"}
                        colorScheme='brand'
                        variant="outline"
                        onClick={() =>checkoutSession()}
                    >
                        Update payment details
                    </Button>

                    <HStack spacing={2}>
                    <Button
                        size={"sm"}
                        colorScheme='brand'
                        variant="outline"
                        onClick={() =>onClose()}
                    >
                        Cancel
                    </Button>

                    <Button
                        size={"sm"}
                        colorScheme='brand'
                        onClick={() =>{save()}}
                    >
                        OK
                    </Button>
                    </HStack>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
                    )
       
    
}


const ChoosePlanModal=({currentPlan, onOK, onClose}:{
    currentPlan?:string,
    onOK:(newPlan)=>any,
    onClose:()=>any
})=>{
    
    const [value, setValue] = useState<string>(currentPlan)
    return (<Modal 
            autoFocus={false}
            
            size="full"
            isOpen={true} onClose={() => {
                onClose();
        }}>
            <ModalOverlay />
            <ModalContent >
              
                <ModalHeader>Choose a plan</ModalHeader>
                <ModalBody   overflow="auto" maxH="80vh" >
                  <Pricing current={currentPlan} selected={value} onSelected={setValue}/>
                </ModalBody>
                <ModalFooter>
                    <HStack justify="space-between" width="100%">
                   <VStack spacing={0} align="start">
                    <Text fontSize="xs" color="gray" fontWeight={500}>Current plan <b>{currentPlan}</b></Text>
                    {currentPlan!=value?<HStack>
                        <Text fontSize="sm" >New selected plan: </Text>
                        <Text color="brand" fontWeight={900} fontSize="sm" >{value}</Text>
                        </HStack>:<Text color="gray"  fontSize="sm" >Select the new plan</Text>}
                   </VStack>

                    <HStack spacing={2}>
                    <Button
                        size={"sm"}
                        colorScheme='brand'
                        variant="outline"
                        onClick={() =>onClose()}
                    >
                        Cancel
                    </Button>

                    <Button
                        size={"sm"}
                        colorScheme='brand'
                        onClick={() =>{onOK(value)}}
                    >
                        OK
                    </Button>
                    </HStack>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
                    )
       
    
}
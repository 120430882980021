import React, { useMemo } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Stack,
    Heading,
    HStack,
    CloseButton,
    Text,
    IconButton,
} from '@chakra-ui/react'
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import { CampaignSteps } from './campaignSteps';
import CampaignStagePreview from './campaignStagePreview';
import STEP_TYPES from './stepTypes';
import { ListProvider } from './listContext';
import { AutoDatePicker, GeniouslyThemeProvider } from 'geniously-chat-ui';
import { CampaignProvider } from './campaignContexts';
import { CampaignEdit } from './campaignEdit';
import { IconArrowsDiagonalMinimize, IconCheck, IconEdit, IconPlayerPlayFilled, IconWindowMaximize, IconWindowMinimize } from '@tabler/icons-react';
import { IconArrowsDiagonal2 } from '@tabler/icons-react';


const CampaignBuilder = ({ campaign, onOk, onCancel, error }: {
    campaign?: Campaign;
    error?: string
    onOk?: (campaign: Campaign) => void;
    onCancel?: () => void;

}) => {
    const [modal, setModal] = React.useState<React.ReactNode>(null)
    const [activeStepId, setActiveStepId] = React.useState()

    function onCancelWithConfirm() {
        setModal(<ConfirmModal
            question='Are you sure you want to cancel?'
            caption='Cancel Campaign Creation'
            onOk={() => onCancel()}
            onCancel={() => setModal(null)}
        />)

    }

    const [campaignState, setCampaignState] = React.useState<Campaign>(campaign || {
        name: "",
        state: "draft",
        steps: []
    })

    const [errors, setErrors] = React.useState<any>({})
    const [editMode, setEditMode] = React.useState(true)
    const [minimized, setMinimized] = React.useState(false)

    const [pendingOk, setPendingOk] = React.useState(false)
    const readOnly = !editMode && campaign?.state !== "draft"
    const edited = useMemo(() => editMode && JSON.stringify(campaignState) !== JSON.stringify(campaign), [editMode, campaignState, campaign])

    const selectedStep = useMemo(() => campaignState?.steps?.find((step) => !readOnly && step.step_id === activeStepId), [campaignState, activeStepId])
    return (
        minimized ? <HStack position="fixed" p="5px 10px" bottom="10px" left="10px" rounded={8} border="2px solid gray" shadow="2xl"
            cursor="pointer"
            background="white" zIndex={10} onClick={() => setMinimized(false)}>
            <Text fontSize="xl" fontWeight={500}>Create campaign</Text>

            <IconWindowMaximize size="20px" />
        </HStack> :
            <Drawer
                isOpen={true}
                placement='right'
                onClose={() => onCancelWithConfirm()}
                closeOnOverlayClick={false}
                size={activeStepId ? "2xl" : "md"}



            >
                <DrawerOverlay />
                <DrawerContent maxW={selectedStep ? ["100vw", "100vw", "100vw", "100vw", "1200px"] : "500px"}>

                    <DrawerCloseButton />
                    <HStack></HStack>


                    <DrawerBody p="0px" >
                        <CampaignProvider campaign={campaignState} onUpdateCampaign={setCampaignState} onErrorsChanged={setErrors}>
                            {modal}
                            <HStack justify="end" height="100%" flexGrow={1}>
                                {selectedStep && <Stack width="100%" height="100%" backgroundColor="gray.50" p="0px">
                                    <Stack height="100%" >
                                        <HStack h="50px" justify="space-between" p="20px">

                                            <Heading size="md">{selectedStep.stage_name || `Step #${campaignState.steps.findIndex((s) => s === selectedStep) + 1}`}</Heading>



                                            <CloseButton onClick={() => setActiveStepId(undefined)} />

                                        </HStack>
                                        <Stack height="100%" backgroundColor="gray.50" borderRadius="10px" p="10px">

                                            <CampaignStagePreview campaignStage={selectedStep} onChange={(val) => {
                                                setCampaignState({
                                                    ...campaignState, steps: campaignState.steps.map(step => step.step_id === selectedStep.step_id ? val : step)
                                                })

                                            }} />
                                        </Stack>
                                        <DrawerFooter>

                                        </DrawerFooter>
                                    </Stack>

                                </Stack>

                                }
                                <Stack shadow="2xl">
                                    <HStack h="50px" justify="space-between" p="20px" mr="20px">

                                        <Heading size="md" fontWeight={900}>{campaign?.state ? "Create campaign" : (editMode ? "Edit campaign" : "Campaign")}</Heading>
                                        < IconButton justifySelf="end" variant="ghost" icon={<IconWindowMinimize size="20px" />} aria-label={"Minimize"} onClick={() => setMinimized(true)} />

                                    </HStack>
                                    <Stack height="calc(100vh - 140px)" flexGrow={1} w="500px" backgroundColor="gray.50" overflow="auto">
                                        <Stack p="10px" flexGrow={1}>
                                            <CampaignEdit
                                                readOnly={readOnly}
                                                activeStepId={activeStepId}
                                                onActiveStepIdChange={(activeStepId) => {
                                                    setActiveStepId(activeStepId)
                                                }}
                                                campaign={campaignState} onChange={(newVal) => {
                                                    setCampaignState(newVal)
                                                }} />
                                        </Stack>
                                        {error && <Stack position="sticky" bottom="0px" p="8px 15px" maxH="3.5em" flexShrink={0} overflow="auto" color="maroon" background="pink.100"><Text>{error}</Text></Stack>}
                                    </Stack>
                                    <DrawerFooter>

                                        {!editMode && <Button colorScheme='brand' p="10px"
                                            leftIcon={<IconEdit size={20} />}
                                            position="absolute"
                                            left={5}
                                            justifySelf="start" mr={3} onClick={() => setEditMode(!editMode)} >
                                            Edit
                                        </Button>}

                                        <Button variant='outline' mr={3} onClick={onCancelWithConfirm}>
                                            Cancel
                                        </Button>


                                        {(campaign?.state == "draft" || editMode) && <Button colorScheme='brand'
                                            isLoading={pendingOk}
                                            leftIcon={(campaign?.state == "draft" ? <IconPlayerPlayFilled /> : <IconCheck />)}
                                            onClick={() => {
                                                if (errors && Object.keys(errors).length > 0) {
                                                    setModal(<ConfirmModal
                                                        question='There some errors in the campaign'
                                                        caption='Campaign validation'
                                                        onOk={() => {

                                                            setModal(null)
                                                        }}

                                                    >
                                                        <Stack>
                                                            {Object.keys(errors).map((key) => <Text color="tomato">{errors[key]}</Text>)}
                                                        </Stack>
                                                    </ConfirmModal>)
                                                }
                                                else {
                                                    setPendingOk(true)
                                                    let okRes = onOk(campaignState) as any
                                                    if (okRes && okRes.finally) {
                                                        okRes.finally(() => setPendingOk(false))
                                                    }
                                                    else {
                                                        setPendingOk(false)
                                                    }
                                                }
                                            }
                                            }>
                                            {campaign?.state === "draft" ? "Confirm and launch" : "Confirm and update"}
                                        </Button>}


                                    </DrawerFooter>
                                </Stack>
                            </HStack>
                        </CampaignProvider>

                    </DrawerBody>

                </DrawerContent>
            </Drawer>
    );
};



export default CampaignBuilder;
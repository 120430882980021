import { Box, Button, HStack, IconButton, Spinner, Stack, Text, Tooltip, useTimeout } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useApiEndpoint, useApiFetch } from '../../utils/useApiHook';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { SearchFilter } from '../SearchFilter/searchFilter';
import Moment from 'react-moment';
import { strictEqual } from 'assert';

interface Props {
    // Define your component's props here
}

const GenericList = (props: {
    endpointPath: string
    listTitle: string
    primaryField: string
    extraFilterRender?: (extraFilterValue: { [queryArg: string]: any }, setExtraFilterValue) => React.ReactNode
    onClickNew?: () => void
    secondaryFields?: string[],
    collapsible?: boolean
    defaultExpanded?: boolean
    onSelected?: (item: any) => void
    selectedId?: string
    customCardRenderer?: (item: any, index?: number, selected?: boolean, refresh?: () => Promise<undefined>) => JSX.Element
}) => {
    // Implement your component logic here
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState("");

    const setExtraFilter = (val) => {
        let filter = Object.fromEntries(Object.entries({ ...Object.fromEntries(searchParams.entries()), ...val }).filter(([k, v]) => v !== undefined && v !== null && v !== "")) as any
        setSearchParams(filter)
    }
    const extraFilter = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams])

    const [queryDebounced, setQueryDebounced] = useState<string>();
    useEffect(() => {
        const timeout = setTimeout(() => {
            setQueryDebounced(query)
        }, 500)
        return () => {
            clearTimeout(timeout)
        }
    }, [query])
    const dataEndpoint = useApiFetch<any[]>(`${props.endpointPath}`, { queryArgs: { q: queryDebounced, ...(extraFilter || {}) }, swrOptions: { revalidateOnFocus: false } })
    const navigate = useNavigate()
    const location = useLocation();
    function handleSelected(item: any) {
        if (props.onSelected) {
            props.onSelected(item)
        }
        else {


            navigate(location.pathname + `/${item.id}`)
        }
    }
    const extraFilterComponent = props.extraFilterRender?.(extraFilter, setExtraFilter) || <></>


    const [collapsed, setCollapsed] = useState(props.defaultExpanded);

    useEffect(() => {
        if (props.collapsible && window.innerWidth < 800) {
            setCollapsed(!!props.selectedId)
        }
    }, [props.selectedId, props.defaultExpanded])

    return (

            <Stack
                background="white.900"
                height="100%"
                // bgGradient="linear(to-b, white, gray.50)"

                justify="start"
                spacing="10px"
                flexGrow={1}
                rounded="5px"
            //maxW="30%"
            position={window.innerWidth < 800 ? "fixed" : undefined}
            zIndex={1}
            backgroundColor="white"
            minWidth={!collapsed ? "400px" : undefined}
            flexShrink={1}
            flexBasis={1}

            >
                <Stack

                    borderWidth={1}
                    rounded={15}
                    overflow="hidden"
                position={collapsed ? "absolute" : "relative"}
                    p="5px"
                m="0px 2px"
                    spacing={0}




                >
                    <HStack justify="space-between">
                        <HStack>
                        {props.collapsible && <IconButton
                            aria-label="show/hide"
                            size="sm"
                            onClick={() => setCollapsed(!collapsed)}
                            icon={!collapsed ? <IconChevronLeft /> : <IconChevronRight />}
                            alignSelf="flex-end"
                        />}
                        {!collapsed && <Text fontWeight={900}>{props.listTitle || "Items"}</Text>}
                        </HStack>
                    {props.onClickNew && !collapsed && < Button
                            variant={"outline"}
                            size="sm"
                            mr="8px"
                            colorScheme="brand"
                            onClick={
                                () => {
                                    let res: any = props.onClickNew()
                                    if (res && res.then) {
                                        res.then(() => {
                                            dataEndpoint.mutate()
                                        })
                                    }
                                }

                            }>New</Button>}
                    </HStack>
                {!collapsed && (
                        <Stack

                            p="10px"
                            overflow="auto"
                            border="5px solid white"
                            justify="start"

                            //maxH="calc(100vh - 125px)"


                        >
                            <Stack
                                position="sticky"
                                m="-5px -5px 0px"
                                top="-15px"
                                p="10px"
                                bgColor="white"

                                zIndex={1}
                            >


                                <SearchFilter
                                    search={query}
                                    onChange={(search, filter) => {
                                        setQuery(search);
                                    }}
                                />
                                {extraFilterComponent}
                            </Stack>
                            {dataEndpoint.isLoading ? (
                                <Stack justifySelf="center" alignSelf="center" flexGrow={1} width="100%" height="100%">
                                    <Spinner size="xl" />
                                </Stack>
                            ) : (
                                dataEndpoint.data?.map((item, i) => (
                                    props.customCardRenderer ? <Stack
                                        cursor="pointer"

                                        onClick={() => handleSelected(item)}
                                    >{props.customCardRenderer?.(item, i, props.selectedId == item.id, dataEndpoint.mutate)}</Stack> :
                                        <ItemCard
                                            key={i}
                                            item={item}
                                            primaryField={props.primaryField}
                                            secondaryFields={props.secondaryFields}
                                            onClick={() => handleSelected(item)}
                                            isSelected={props.selectedId == item.id}
                                        />
                                ))
                            )}
                        </Stack>
                    )}
                </Stack>
            </Stack>

    );
};

const ItemCard = ({ item, onClick, isSelected, primaryField, secondaryFields }: {
    item: any,
    primaryField: string,
    secondaryFields: string[],
    onClick: () => any, isSelected: boolean
}) => {
    function testIfIsDate(str: any) {
        if (typeof str !== 'string') return false
        // test if matches iso date format
        return str.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[\.\d+Z]*$/)
    }
    return (
        <Stack
            p="10px"
            m="5px"
            border="1px solid gray"
            borderRadius="8px"
            color="gray"
            onClick={onClick}
            cursor="pointer"
            bg={isSelected ? "gray.100" : "white"}
            _hover={{ shadow: "xl" }}
            align="start"
        >
            <Text fontWeight={900}>{item[primaryField]}</Text>
            <Stack spacing={0}>
            {secondaryFields?.map((field, i) => {

                if (testIfIsDate(item[field])) {
                    return <Tooltip label={<Moment>{item[field]}</Moment>}><Text as={"span"} key={i} fontSize="xs">{<Moment fromNow>{item[field]}</Moment>}</Text></Tooltip>
                }
                else {

                    return <Text key={i} fontSize="xs">{item[field]}</Text>
                }


            })}
            </Stack>

        </Stack>
    )
}

export default GenericList;
import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';

interface Option {
    label: string;
    value: any;
    icon?: any;
}
interface DropDownProps {
    value: any;
    options: string[] | Option[];
    onSelect: (optionValue: any) => void;
    placeholder?: string;
    [key: string]: any
}

const DropDown: React.FC<DropDownProps> = ({ value, options, onSelect, placeholder, ...rest }: DropDownProps) => {

    const optionsMap = useMemo(() => {
        // object where key is option-item value if option is string or option.value if option is object
        if (options?.length) {

            return (options as (string | Option)[]).reduce((acc, option) => {
                acc[typeof option === "string" ? option : option.value] = option;
                return acc;
            }, {} as Record<string, string | Option>)
        }
        else
            return []
    }, [options])

    function formatOption(opt: string | Option) {
        return <HStack >
            {(opt as Option)?.icon}

            <Text> {(typeof opt === "string" ? opt : opt?.label) || (opt as any)}</Text>
        </HStack >
    }

    return (
        <Menu {...rest} >
            <MenuButton variant="outline" flexShrink={0} as={Button} size="sm" aria-label="Step type" rightIcon={<IconChevronDown color="gray" size="20px" />}  {...rest} >
                <Text color={!value ? "lightgray" : undefined}>

                    {(optionsMap && value && formatOption(optionsMap[value])) || placeholder || "Select Option"}
                </Text>
            </MenuButton>
            <MenuList>
                {options?.map((opt) => (
                    <MenuItem onClick={() => onSelect(opt.value || opt)}>
                        {formatOption(opt)}
                    </MenuItem>
                ))}


            </MenuList>
        </Menu>
    );
};

export default DropDown;
import { Stack, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';

interface ValidationTagProps {
    errors: Record<string, any> | null | undefined;
    keyPath: string;
    [key: string]: any;
}

const ValidationTag: React.FC<ValidationTagProps> = ({ errors, keyPath, ...rest }) => {
    const getError = (errors: Record<string, any>, keyPath: string): string | null => {
        return errors ? errors[keyPath] : null;
        // const keys = keyPath.split('.');
        // let error = errors;

        // for (const key of keys) {
        //     if (error[key] !== undefined) {
        //         error = error[key];
        //     } else {
        //         return null;
        //     }
        // }

        // return typeof error === 'string' ? error : null;
    };

    const errorText = React.useMemo(() => getError(errors, keyPath), [errors, keyPath]);

    return errorText ? <Tooltip label={<Text whiteSpace="pre-wrap" >{errorText}</Text>}>
        <Stack>
            <Text fontSize="xs" color="tomato" noOfLines={1} {...rest}>{errorText}</Text>
        </Stack>
    </Tooltip> : <></>;
};

const WithValidationTag = ({ children, errors, keyPath, ...rest }: ValidationTagProps & { children: React.ReactNode }) => {
    return <Stack spacing={0}>
        {children}
        <ValidationTag errors={errors} keyPath={keyPath} {...rest} />
    </Stack>;
};

export { ValidationTag, WithValidationTag };
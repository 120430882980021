import { Box, Button, Card, CardBody, CardHeader, Heading, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack, Tag, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { EditableField } from '../EditableField/editableField';
import { IconAffiliateFilled, IconBrandLinkedin, IconChevronDown, IconClockPause, IconDots, IconDotsVertical, IconHeartHandshake, IconX } from '@tabler/icons-react';

import { animate, MotionValue, Reorder, useDragControls, useMotionValue, } from "framer-motion"
import { IconMessage } from '@tabler/icons-react';
import { IconGripVertical } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import DropDown from '../DropDown/dropDown';
import { IconArrowBigLeftFilled } from '@tabler/icons-react';
import STEP_TYPES from './stepTypes';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from '@choc-ui/chakra-autocomplete';
import { useListContext } from './listContext';
import ColumnPicker from './columnPicker';
import { AutoDatePicker, AutoUI, GeniouslyThemeProvider } from 'geniously-chat-ui';
import { WithValidationTag } from '../ErrorValidation/validationTag';
import { useCampaignContext } from './campaignContexts';
const CampaignSteps = ({ steps, onChange, readOnly, activeStepId, onActiveStepIdChange: setActiveStepId }: {
    steps: CampaignStage[]
    onChange: (steps: CampaignStage[]) => void,
    readOnly?: boolean
    activeStepId?: any
    onActiveStepIdChange?: (stepId: any) => void
}) => {


    const [order, setOrder] = React.useState<any[]>(steps)

    useEffect(() => {
        setOrder(steps)
    }, [steps])



    function finishReorder() {
        onChange(order)
    }

    return (

        <Stack height="100%" flexGrow={1} spacing={0}>

            <Stack as={Reorder.Group}
                height="100%"
                flexGrow={1}
                //axis="y"
                //p="0px"
                onReorder={val => {
                    // onChange(order.map((id) => steps.find((step) => step.step_id == id) as CampaignStage))
                    setOrder(val)
                    //onChange(val)


                }}

                layoutScroll

                values={order}

                spacing={0}>

                {order.map((step, index) => (


                    <CampaignStepCard key={step.step_id} idx={index} step={step} readOnly={readOnly} onChange={(step) => {
                        let newSteps = [...steps]
                        newSteps[index] = step
                        onChange(newSteps)
                    }}
                        active={activeStepId == step.step_id}
                        onClick={() => setActiveStepId && setActiveStepId(step.step_id)}
                        isFirst={index == 0}
                        isLast={index == order.length - 1}
                        onDragEnd={() => {
                            finishReorder()
                        }}
                    />

                ))}
            </Stack>
        </Stack >
    );
};




const CampaignStepCard = ({ idx, step, readOnly, isFirst, isLast, active, onClick, onChange, onDragEnd, columns = ["tomato", "banana"] }: {

    idx: number;
    isLast?: boolean
    isFirst?: boolean
    readOnly?: boolean
    step: CampaignStage
    active?: boolean
    columns?: string[]
    onChange?: (step: CampaignStage) => void
    onClick?: () => void
    onDragEnd?: () => void
}) => {
    const controls = useDragControls()

    const stepTypes = STEP_TYPES

    const [delayUnits, setDelayUnits] = useState(step.delay_days && Math.round(step.delay_days) != step.delay_days ? "hours" : "days")

    const [hover, setHover] = useState(false)
    const border = hover ? "1px solid darkred" : (active ? "1px solid #bf0a0a" : "1px solid lightgray")
    const campaignContext = useCampaignContext()

    function getStepSettings() {
        return <Stack spacing="4px">

            {(step.step_type?.includes("email-message")) &&
                <Stack spacing={0}>
                    <Text fontSize="xs" mb="-6px" fontWeight={600} p="2px" backgroundColor="white" >Subject </Text>
                    {!readOnly ? (
                        <WithValidationTag errors={campaignContext?.campaignErrors} keyPath={`steps.${idx}.subject`}>
                            <ColumnPicker allowCustom value={step.subject_source_column || step.subject}
                                onSelected={(val) => {
                                    onChange({ ...step, subject_source_column: val, subject: undefined })
                                }

                                }
                                onChange={(val) => {
                                    onChange({ ...step, subject: val })
                                }
                                } />
                        </WithValidationTag>
                    ) : (
                        !step.subject_source_column ? <Text fontSize="sm" color="black">{step.subject}</Text> : <Tag>
                            {step.subject_source_column}
                        </Tag>
                    )}
                </Stack>
            }
            <Stack spacing={0}>
                <Text fontSize="xs" mb="-6px" fontWeight={600} p="2px" backgroundColor="white" >Message </Text>
                {!readOnly ? (
                    <WithValidationTag errors={campaignContext?.campaignErrors} keyPath={`steps.${idx}.message`}>

                        <ColumnPicker allowCustom value={step.source_column || step.message}
                            onSelected={(val) => {
                                onChange({ ...step, source_column: val, message: undefined })
                            }
                            }
                            onChange={(val) => {
                                onChange({ ...step, message: val, source_column: val ? undefined : step.source_column })
                            }
                            } />
                    </WithValidationTag>
                ) : (
                    !step.source_column ? <Text fontSize="sm" color="black">{step.message}</Text> : <Tag alignSelf="start">
                        {step.source_column}
                    </Tag>
                )}
            </Stack>

        </Stack >
    }

    return (
        <Stack key={step.step_id}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}

            position="relative"
        //
        >
            <Stack
                className='background'
                position="absolute"
                top="0px"
                left="42px"
                flexGrow={1}
                width="10px"
                height={!isLast ? "100%" : "100px"}
                css={`
                background-image: linear-gradient(to bottom, #cacaca 50%, transparent 50%);
                background-size: 2px 10px; /* Line width and dash spacing */
                background-position: 0px 0; /* 100px from the left */
                background-repeat: repeat-y; /* Repeat vertically */
        `}
            />
            <HStack width="100%" align="stretch" mt="12px">
                {!isFirst && (
                    <Tooltip label="Delay between steps">
                        <HStack align="center" opacity={step?.delay_days ? 1 : 0.4}>

                            <Stack align="center" flexGrow={0}>

                                <Stack height="30px" width="30px" border="1px solid #cacaca" borderRadius="full" p="10px" m="15px 5px 15px 30px" position="relative" align="center" justify="center" backgroundColor="white">
                                    <Box >
                                        <IconClockPause color="gray" />

                                    </Box>


                                </Stack>

                            </Stack>
                            {readOnly ?
                                <Text fontSize="sm" color="black">{step?.delay_days || 0} {delayUnits}</Text> :
                                (<HStack align="center" flexGrow={1} backgroundColor="white" borderRadius="4px" border="1px solid lightgray" p="4px 8px">
                                    <EditableField fontSize="sm" value={step?.delay_days ? Math.round(((delayUnits == "hours" ? 24 : 1) * step?.delay_days)).toString() : ""} placeholder={"0"} onApply={(value) => {
                                        if (value == "") {
                                            onChange(
                                                { ...step, delay_days: undefined }
                                            )
                                            return
                                        }
                                        if (isNaN(parseInt(value))) return

                                        let newVal = Math.round(1000 * parseInt(value) / (delayUnits == "hours" ? 24 : 1)) / 1000
                                        onChange(
                                            { ...step, delay_days: newVal }
                                        )
                                    }} />
                                    <DropDown options={["days", "hours"]} value={delayUnits ? delayUnits : undefined} onSelect={(val) => setDelayUnits(val)} />
                                </HStack>)

                            }

                        </HStack>
                    </Tooltip>
                )
                }

            </HStack>

            <HStack borderRadius="8px" align="start" spacing={0} onClick={onClick}>
                <HStack width="100%" align="stretch"
                    as={Reorder.Item}
                    value={step}

                    whileDrag={{ scale: 0.9, rotate: -3 }}
                    dragListener={false}
                    dragControls={controls}

                >
                    <Stack height="40px" width="15px" justifyContent="center" flexShrink={0} _hover={{ opacity: 1 }} opacity={0.2} cursor="grab"
                        onPointerDown={(e) => controls.start(e)}
                        onPointerUp={() => onDragEnd && onDragEnd()}
                    >
                        <IconGripVertical color="gray" />
                    </Stack>
                    <Stack align="center"  >
                        <Stack align="center" flexGrow={0}>

                            <Stack height="40px" width="40px" border={border} borderRadius="8px" p="5px" position="relative" align="center" justify="center" backgroundColor={active ? "#ffefef" : "white"} _hover={{ cursor: "pointer" }} >
                                <Box>

                                    {stepTypes[step.step_type]?.icon}
                                </Box>
                                <Box position="absolute" bottom="5px" right="5px" backgroundColor="white">
                                    {stepTypes[step.step_type]?.subIcon}

                                </Box>
                            </Stack>

                        </Stack>
                        {/*  */}
                    </Stack>
                    <Card border={border} borderRadius="8px" flexGrow={1} _hover={{ cursor: "pointer" }}>
                        <CardHeader p="8px 10px" width="100%" >
                            <Stack >
                                <HStack width="100%" justify="space-between">
                                    <HStack flexGrow={1}>
                                        <Heading size='md'>#{idx + 1}</Heading>
                                        <Menu >
                                            <MenuButton isDisabled={readOnly} variant="outline" flexShrink={0} as={Button} size="sm" aria-label="Step type" rightIcon={readOnly ? undefined : <IconChevronDown color="gray" size="20px" />} >
                                                <Text color={!step.step_type ? "red" : undefined}>

                                                    {stepTypes[step.step_type]?.name || step.step_type || "Select Step Type"}
                                                </Text>
                                            </MenuButton>
                                            {!readOnly && <MenuList>
                                                {Object.keys(stepTypes).map((type) => (
                                                    <MenuItem onClick={() => onChange({ ...step, step_type: type })}>{stepTypes[type].name}</MenuItem>
                                                ))}
                                            </MenuList>}
                                        </Menu>

                                    </HStack>

                                    {!readOnly && <Menu>
                                        <MenuButton as={IconButton} variant="ghost" size="sm" aria-label="Options" icon={<IconX color="gray" size="20px" />} />
                                        <MenuList>
                                            {/* <MenuItem>Refresh messages</MenuItem> */}
                                            <MenuItem color="#fa0f0f" onClick={() => onChange(null)}>Confirm Delete</MenuItem>
                                        </MenuList>

                                    </Menu>}

                                </HStack>
                                {!readOnly ? (
                                    <EditableField label="Stage Name" value={step.stage_name} placeholder={"Name of the step"} onApply={(value) => onChange({ ...step, stage_name: value })} />

                                ) : (
                                    <Text m="6px" fontSize="sm" fontWeight={900} color="gray">{step.stage_name}</Text>
                                )}
                            </Stack>
                        </CardHeader>

                        <CardBody p="0px 20px 10px" >
                            {getStepSettings()}
                            {/* 
                    <h2>{step.stage_name}</h2>
                    <p>Step ID: {step.step_id + 1}</p>
                    <p>Step Type: {step.step_type}</p>
                    <p>Delay Days: {step.delay_days}</p>
                    <p>Settings: {JSON.stringify(step.settings)}</p> */}
                        </CardBody>
                    </Card>
                </HStack>
            </HStack>
        </Stack>
    );
};

const CampaignStepExtendedSettings = ({ step, onChange }: { step: CampaignStage, onChange: (step: CampaignStage) => void }) => {


    const settingsSchema = useMemo(() => {
        let schema = {
            type: "object",
            properties: {
                treat_as_message_if_connected: { type: "boolean", title: "Treat as LinkedIn Message if Connected" },
                track_open_rate: { type: "boolean", title: "Track Open Rate" },
                ignore_step_if_no_email: { type: "boolean", title: "Ignore Step if email is not provided" },


                ignore_step_if_no_linkedin: { type: "boolean", title: "Ignore Step if No LinkedIn" },

                review_each_message_before_sending: { type: "boolean", title: "Review Each Message Before Sending" },
            }
        }
        let fieldFilter = []
        if (step.step_type == "email-message" || step.step_type == "email-reply") {

            fieldFilter = ["track_open_rate", "ignore_step_if_no_email", "review_each_message_before_sending"]

        }
        else if (step.step_type == "linkedin-connect") {
            fieldFilter = ["ignore_step_if_no_linkedin", "treat_as_message_if_connected", "review_each_message_before_sending"]

        }
        else if (step.step_type == "linkedin-message") {
            fieldFilter = ["ignore_step_if_no_linkedin", "review_each_message_before_sending"]

        }
        Object.keys(schema.properties).forEach((field) => {
            if (!fieldFilter.includes(field))
                delete schema.properties[field]
        })
        return schema
    }, [step])

    const settings = useMemo(() => {
        let res = step?.settings || {};
        ["track_open_rate", "ignore_step_if_no_email", "review_each_message_before_sending", "ignore_step_if_no_linkedin", "treat_as_message_if_connected"].forEach((field) => {
            if (!res[field]) {
                res[field] = false
            }
        })
        return res
    }, [step?.settings])
    return <Stack>

        <GeniouslyThemeProvider>
            <AutoUI
                value={settings}
                showOnlySchemaFields={true}
                schema={settingsSchema as any}

                onValueChange={(val) => {
                    onChange({ ...step, settings: val as any })
                }}

            />
        </GeniouslyThemeProvider>
    </Stack>
}



export { CampaignSteps, CampaignStepCard, CampaignStepExtendedSettings };
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface CampaignContextProps {
    currentCampaign: Campaign,
    updateCurrentCampaign: (campaign: Campaign) => void
    onErrorsChanged?: (errors: { [key: string]: string }) => void
    campaignErrors: { [key: string]: string };

}

const CampaignContext = createContext<CampaignContextProps | undefined>(undefined);

const CampaignProvider: React.FC<{
    campaign: Campaign,
    onUpdateCampaign?: (campaign: Campaign) => any,
    onErrorsChanged?: (errors: { [key: string]: string }) => void,
    children: ReactNode
}> = ({ campaign, onUpdateCampaign, onErrorsChanged, children }) => {
    const [campaignErrors, setCampaignErrors] = useState<{ [key: string]: string }>({});
    const [currentCampaign, updateCurrentCampaign] = useState<Campaign>(campaign);
    useEffect(() => {
        if (currentCampaign !== campaign && onUpdateCampaign) {
            onUpdateCampaign(currentCampaign);
        }
    }, [currentCampaign]);

    const validate = () => {
        // Private function to calculate errors

        const errors: { [key: string]: string } = {};
        // Add your error calculation logic here
        if (!currentCampaign.name) {
            errors.campaign_name = 'Campaign name is required';
        }
        if (!currentCampaign.steps?.length) {
            errors.campaign_name = 'At least one step in campaign is required';
        }
        else {
            currentCampaign.steps.forEach((step, index) => {
                if (!step.step_type) {
                    errors[`steps.${index}.subject`] = `step #${index + 1}: Step type is required`;
                }
                else if (step.step_type.includes("email")) {
                    if (!currentCampaign.email_accounts?.length) {
                        errors.email_accounts = "Email account is required for email steps"
                    }
                    if (!currentCampaign.column_mappings?.email) {
                        errors["contact_column_mappings.email"] = "Email column is required for email steps"
                    }

                }

                else if (step.step_type.includes("linkedin")) {
                    if (!currentCampaign.linkedin_accounts?.length) {
                        errors.linkedin_accounts = "Linkedin account is required for linkedin steps"
                    }
                    if (!currentCampaign.column_mappings?.linkedin_url) {
                        errors["contact_column_mappings.linkedin_url"] = "Linkedin URL column is required for linkedin steps"
                    }
                }

                if (step.step_type == "email-message" && !(step.subject || step.subject_source_column)) {
                    errors[`steps.${index}.subject`] = `step #${index + 1}: Subject is required for email message`;
                }
                if (!(step.message || step.source_column)) {
                    errors[`steps.${index}.message`] = `step #${index + 1}: Message is required`;
                }
            });
        }

        if (JSON.stringify(campaignErrors) !== JSON.stringify(errors)) {
            setCampaignErrors(errors);
            onErrorsChanged && onErrorsChanged(errors);
        }

    };

    useEffect(() => {
        updateCurrentCampaign(campaign);
    }, [campaign])

    // Set campaign errors using the private function
    useEffect(() => {
        if (currentCampaign) {
            validate()
        }
    }
        , [currentCampaign]);

    return (
        <CampaignContext.Provider value={{ currentCampaign, updateCurrentCampaign, campaignErrors }}>
            {children}
        </CampaignContext.Provider>
    );
};

const useCampaignContext = (): CampaignContextProps => {
    const context = useContext(CampaignContext);
    if (!context) {
        throw new Error('useCampaignContext must be used within a CampaignProvider');
    }
    return context;
};

export { CampaignProvider, useCampaignContext };
import { useEffect, useMemo, useRef, useState } from "react";

import {

    Stack,

    Text,
    Tooltip,

} from "@chakra-ui/react";
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
} from '@chakra-ui/react'



function SliderWithMarks({ marks, markUnit, limits, value, onChange
}: {
    value: number,
    onChange: (value: number) => void,
    marks?: number[],
    markUnit?: string,
    limits?: {
        limit: number,
        color: string
        info: string
    }[]

}) {
    const [sliderValue, setSliderValue] = useState(value || 0)
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const currentLimit = useMemo(() => {
        if (!limits) return
        let limitsReversed = [...limits].reverse()
        return limitsReversed?.find(l => sliderValue > l.limit) || limits[0];

    }, [sliderValue, limits])

    useEffect(() => {
        setSliderValue(value)
        const timer = setTimeout(() => {
            onChange(sliderValue)
        }, 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [value])


    const min = limits?.length ? limits[0].limit : 0
    const max = limits?.length ? limits[limits.length - 1].limit : 100
    return (
        <Stack p="0px 20px 10px" spacing={0}>
            <Text fontWeight={600} color="gray.600" fontSize="xs" textAlign={sliderValue > (min + ((max - min) / 2)) ? "start" : "end"}>{currentLimit?.info}</Text>
            <Slider
                id='slider'
                value={sliderValue}
                min={min}
                max={max}

                onChange={(v) => setSliderValue(v)}
                onBlur={() => onChange(sliderValue)}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}

            >
                {marks?.map((mark) => (
                    <SliderMark value={mark} mt='1' ml='-2.5' fontSize='sm' fontWeight={700} color="blackAlpha.500">
                        {mark}{markUnit}
                    </SliderMark>
                ))}

                <SliderTrack>
                    <SliderFilledTrack backgroundColor={currentLimit?.color || "teal"} />
                </SliderTrack>
                <Tooltip

                    hasArrow
                    bg="blackAlpha.800"
                    color='white'
                    placement='top'
                    isOpen={tooltipOpen}
                    label={`${sliderValue}${markUnit || ""}`}
                >
                    <SliderThumb />
                </Tooltip>
            </Slider>
        </Stack>
    )
}

export default SliderWithMarks;
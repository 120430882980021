import { IconBrandLinkedin, IconMail } from "@tabler/icons-react";

const STEP_TYPES: {
    [key: string]: {
        name: string,
        icon: JSX.Element,
        subIcon?: JSX.Element
    }
} = {
    "linkedin-connect": {
        name: "LinkedIn Connect",
        icon: <IconBrandLinkedin color="#0a66c2" size="30px" />,
        //subIcon: <IconAffiliateFilled size="15px" color="#0a66c2" />
    },
    "linkedin-message": {
        name: "LinkedIn Message",
        icon: <IconBrandLinkedin color="#0a66c2" size="30px" />,

    },
    "email-message": {
        name: "New Email",
        icon: <IconMail size="30px" />
    },
    "email-reply": {
        name: "Reply to Email",
        icon: <IconMail size="30px" />,
        //subIcon: <IconArrowBigLeftFilled size="15px" />
    }
}
export default STEP_TYPES

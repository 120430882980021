

import { useCallback, useEffect, useMemo, useRef, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Editable, EditableInput, EditablePreview, filter, MenuDivider, MenuOptionGroup, TagLabel, Tooltip } from "@chakra-ui/react";

import { useAppContext } from "../appContext";
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";

import { TopicInsightsBox } from "../components/Insights/topicInsights";
import { AutoUI, ChatInput, GeniouslyCommander, ChatService, ChatStatusBadge, GeniouslyThemeProvider, StatusMessage, CommandResultMessage, WidgetContainer } from "geniously-chat-ui";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import { IconArrowsDiagonal2, IconAsterisk, IconBoxAlignBottomLeftFilled, IconCheck, IconChevronDown, IconChevronRight, IconChevronsDown, IconChevronUp, IconCircleCheckFilled, IconExclamationCircle, IconFileDownload, IconHistory, IconMailFast, IconPlayerEjectFilled, IconPlayerPauseFilled, IconPlayerPlay, IconPlayerPlayFilled, IconPlayerTrackNextFilled, IconPlus, IconPointFilled, IconRefresh, IconRepeat, IconSend, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { LuImport } from "react-icons/lu";
import { Reorder } from "framer-motion";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import useSWR, { mutate } from "swr";
import { ConfirmModal } from "../components/ModalDialogs/confirmModal";
import { EditModal } from "../components/ModalDialogs/editModal";
import { IconFilter } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconChevronLeft } from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { InfoModal } from "../components/ModalDialogs/infoModal";
import Navigation from "../components/Navigation/navigation";
import GenericList from "../components/GenericList/genericList";
import { IconArrowsDiagonalMinimize } from "@tabler/icons-react";
import { IconWindowMaximize } from "@tabler/icons-react";
import { ListOpDialog } from "../components/Lists/listOpDialog";
import {
    CommandSectionDescription,
    DataListColumn,
    DataListInfo,
    ListOp,
    ListPreview
} from "../components/Lists/model";
import { IconCircleDashed } from "@tabler/icons-react";
import { IconRotateClockwise2 } from "@tabler/icons-react";
import ColumnsSelector from "../components/listUI/columnsSelector";
import { ListOpsWidget } from "../components/Lists/listOpsWidget";
import { ListVersionsComponent } from "../components/Lists/listVersionsComponent";
import { FilterButton } from "../components/Lists/filterButton";
import CampaignBuilder from "../components/Campaigns/campaignBuilder";
import { ListProvider } from "../components/Campaigns/listContext";


const ReRunOptions = ({ optionsValue, setOptionsValue }: {
    optionsValue: { newRecords: boolean, emptyAndFailed: boolean, allRecords: boolean },
    setOptionsValue: (value: any) => void
}) => {

    return <Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["newRecords"]} onChange={(e) => {
                setOptionsValue({ emptyAndFailed: optionsValue.emptyAndFailed, "newRecords": e.target.checked })
            }}>New records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">Records that have been added later, or record that have been filtered out by the view</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["emptyAndFailed"]} onChange={(e) => {
                setOptionsValue({ newRecords: optionsValue.newRecords, "emptyAndFailed": e.target.checked })
            }}>Empty and failed</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">This includes all records that don't have value (relevant for column operations)</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["allRecords"]} onChange={(e) => {
                setOptionsValue({ "allRecords": e.target.checked })
            }}>All records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">All current values (overwrite existing)</Text>
        </Stack>
    </Stack>
}

const RunOptions = ({ options = [10, 50, 100, 200, 500], stopAfter, onStopAfterChange }: {
    options?: number[],
    stopAfter: number | null,
    onStopAfterChange: (value: number | null) => void
}) => {
    function optionToString(option: number | null) {
        return option ? `stop after ${option} rows` : "Process to the end"
    }
    return <Stack>

        <Menu>
            <MenuButton as={Button} rightIcon={<IconChevronDown />} size="xs" variant="outline" colorScheme="red" >{optionToString(stopAfter)}</MenuButton>
            <MenuList>
                {[...options, null].map((opt, i) => (
                    <MenuItem key={i} onClick={() => {
                        onStopAfterChange(opt)
                    }}>{optionToString(opt)}</MenuItem>
                ))}

            </MenuList>
        </Menu>
    </Stack>
}

const RunOptionsDialog = ({ defaults, onOk, onCancel }: {
    defaults?: {
        pause_after_steps?: {
            default: number,
            options: number[]
        }
    }
    onOk: (options: { overwrite?: null | string, pause_after_steps?: number }) => void,
    onCancel: () => void
}) => {
    return <EditModal size="sm" withFullscreenSwitch={false} caption="Continue operation" value={defaults?.pause_after_steps?.default}
        onOk={(val: any) => {
            onOk({ overwrite: null, pause_after_steps: val })
        }}
        onCancel={() => {
            onCancel()
        }}>
        {(val, setVal) => (<Stack>
            <Text>Do you want to run the op the end of the list?</Text>
            <RunOptions stopAfter={val} onStopAfterChange={setVal} options={defaults?.pause_after_steps?.options} />
        </Stack>)}
    </EditModal>
}

const ListOpsForm = ({ importOps, listOps, currentOp, executorState, openVersions, scheduledOps, onOpAction, onExecutorAction }: {
    listOps: ListOp[]
    importOps?: ListOp[],
    openVersions?: () => void,
    currentOp?: ListOp,
    executorState: "running" | "ready" | "paused"
    scheduledOps: ListOp[]
    onOpAction?: (op: ListOp | "all", action: "pause" | "resume" | "stop" | "retry" | "rerun" | "cancel", options?: any) => Promise<any>
    onExecutorAction?: (action: "stop" | "resume") => Promise<any>
}) => {

    function getStateIcon(state: "init" | "running" | "paused" | "stopped" | "done" | "error") {
        if (state == "init") {
            return <IconCircleDashed size="15px" />
        }

        else if (state == "paused") {
            return <IconPlayerPauseFilled size="15px" />
        }
        else if (state == "stopped") {
            return <IconPlayerPauseFilled size="15px" />
        }
        else if (state == "done") {
            return <Stack color="#50C878"><IconCircleCheckFilled size="15px" /></Stack>
        }
        else if (state == "error") {
            return <Stack color="#FF5733"><IconExclamationCircle color="crimson" size="15px" /></Stack>

        }
        else if (state == "running") {
            return <Stack ><Spinner size="xs" /></Stack>

        }
        else {
            return <Text>state</Text>
        }
    }
    const [modal, setModal] = useState<any>()
    const [waiting, setWaiting] = useState<boolean>()
    const [error, setError] = useState<string>()

    function getListOpsAccordion(listOps: ListOp[]) {
        return <Accordion allowToggle index={undefined} >
                {listOps?.map((op, i) => (
                    <AccordionItem key={i} border="1px solid lightgray" borderRadius={4} mt="4px" >
                        {({ isExpanded }) => (
                            <>
                                <HStack>
                                    <AccordionButton p="2px"  >
                                    <AccordionIcon />
                                        <Stack flexGrow={1} align="start">
                                            <Text >{op.name} </Text>


                                        </Stack>

                                </AccordionButton>
                                    <HStack m="0px 8px">
                                        {/* <IconButton className="row-btn" aria-label="ss" size="xs" variant="ghost" icon={<IconRefresh size="15px" />} onClick={() => {

                                        }} /> */}
                                        {op.state_message && <Tooltip label={op.state_message} >
                                            <Tag maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" ><TagLabel>{op.state_message}</TagLabel></Tag>
                                        </Tooltip>}
                                        {op.processed_count && (
                                            <Tooltip label={op.processed_count + " processed records"}>
                                                <Tag size="sm" colorScheme="gray" ><IconCheck size="12px" /> {op.processed_count}</Tag>
                                            </Tooltip>

                                        )}
                                        {op.imported_count && (
                                            <Tooltip label={`Imported ${op.imported_count} records out of ${op.total_records || '?'}`}>
                                                <Tag size="sm" colorScheme="gray" whiteSpace="nowrap"><IconCheck size="12px" /> {op.imported_count} / <b> {op.total_records || "?"}</b></Tag>
                                            </Tooltip>

                                        )}
                                        {
                                            <Tooltip label={op.state} >
                                                {getStateIcon(op.state)}
                                            </Tooltip>
                                        }

                                        {(op.state == "paused" || op.state == "stopped" || op.state == "error") && <Button size="xs" variant="outline" colorScheme="red" onClick={() => {
                                            setModal(
                                                <RunOptionsDialog onOk={(options: any) => {


                                                    onOpAction(op, "rerun", options).then(() => {
                                                        setModal(undefined)
                                                    })


                                                }} onCancel={() => {
                                                    setModal(undefined)
                                                    }} />

                                            )

                                        }} >{op.state == "error" ? "Try continue" : "Continue"}</Button>}
                                    </HStack>
                                </HStack>
                                <AccordionPanel p="0px">
                                    <Stack p="8px">
                                        <Text fontSize="md" fontWeight={900} mb="-8px">{op.op_type}</Text>
                                        {isExpanded && op.kwargs && Object.keys(op.kwargs).length && (
                                            <AutoUI value={op.kwargs} />
                                        )}
                                        {op.stages && (
                                            op.stages.map((stage, i) => (
                                                <Accordion allowToggle index={undefined} >
                                                    <AccordionItem border="1px solid lightgray" borderRadius={4} mt="4px" >
                                                        <AccordionButton p="2px"  >
                                                            <Text fontSize="sm">- {stage.name}</Text>
                                                        </AccordionButton>
                                                        <AccordionPanel p="0px">
                                                            <AutoUI value={stage.args} schema={stage.args_schema} />
                                                        </AccordionPanel>

                                                    </AccordionItem>
                                                </Accordion>

                                            ))
                                        )}
                                        {op.filters && op.filters.map && (
                                            <Stack spacing={2}>
                                                <HStack>
                                                    <IconFilter size="15px" />
                                                    <Text fontWeight={700} fontSize={13}>Applied filters</Text>
                                                </HStack>
                                                <Stack p="0px 8px" spacing={1} fontSize={13} >
                                                    {op.filters.map((filter, i) => (
                                                        <HStack key={i}>
                                                            <IconPointFilled size="15px" />
                                                            {typeof (filter) === "string" ? (
                                                                <Text >{filter}</Text>
                                                            ) : (
                                                                <Tooltip label={filter.expression}>
                                                                    <HStack>

                                                                        <Text>{filter.name}</Text>
                                                                    </HStack>
                                                                </Tooltip>
                                                            )}
                                                        </HStack>
                                                    ))}

                                                </Stack>
                                            </Stack>
                                        )}
                                        <HStack>
                                            <Button alignSelf="end" size="xs"
                                            onClick={() => {
                                                onOpAction(op, "retry")

                                            }}
                                                leftIcon={<IconRefresh size="15px" />} >Edit / Retry</Button>

                                            {/* {<Button alignSelf="end" size="xs"
                                                onClick={() => {
                                                    if (op.category == "add_column") {


                                                        setModal(
                                                            <EditModal caption="Re-run operation" value={{}} onOk={(val: any) => {
                                                                if ((val && Object.keys(val).length)) {
                                                                    let overwrite = null;

                                                                    if (val.emptyAndFailed) {
                                                                        overwrite = "empty_and_failed"
                                                                    }
                                                                    else if (val.allRecords) {
                                                                        overwrite = "all"
                                                                    }
                                                                    onOpAction(op, "rerun", { overwrite: overwrite }).then(() => {
                                                                        setModal(undefined)
                                                                    })

                                                                }
                                                            }} onCancel={() => {
                                                                setModal(undefined)
                                                            }}>
                                                                {(val, setVal) => (<Stack>
                                                                    <Text>Are you sure you want to re-run the operation?</Text>
                                                                    <ReRunOptions optionsValue={val as any} setOptionsValue={setVal} />
                                                                </Stack>)}
                                                            </EditModal>
                                                        )
                                                    }
                                                    else{
                                                        onOpAction(op, "rerun", )
                                                    }

                                                }}
                                                leftIcon={<IconRotateClockwise2 size="15px" />} >Re-run</Button>} */}




                                        </HStack>

                                    </Stack>
                                </AccordionPanel>
                            </>)}
                    </AccordionItem>
                ))}
            </Accordion>
    }


    return (<Stack >
        {modal}
        {waiting && <Spinner />}
        <Stack spacing={1} opacity={waiting ? 0.5 : undefined} pointerEvents={waiting ? "none" : undefined} maxH="70vh" overflow="auto">
            <HStack width="100%" justify="space-between">
                <Text>Sources</Text>
                <Button colorScheme="brand" size="sm" variant="outline" justifySelf="start" leftIcon={<IconHistory />} onClick={() => openVersions()}>Version history</Button>

            </HStack>
            {getListOpsAccordion(importOps)}
            <HStack width="100%" justify="space-between" pt="15px">
            <Text>Operations</Text>
                <Menu>
                    <MenuButton as={Button} leftIcon={<Box transform="rotate(90deg)"><IconPlayerEjectFilled size="18px" /></Box>} rightIcon={<IconChevronDown />} size="xs" variant="outline" colorScheme="brand" >Re-run operations</MenuButton>
                    <MenuList fontSize="xs">


                        <MenuItem icon={<HStack spacing="0px"><IconPlayerPlayFilled size="15px" /><IconAsterisk style={{ position: "relative", top: "-5px", margin: "-5px" }} size="10px" /></HStack>} iconSpacing={2} onClick={() => {
                            setWaiting(true)
                            onOpAction("all", "rerun").catch(e => {
                                setError(e.toString())
                            })
                                .finally(() => {
                                    setWaiting(false)

                                })
                        }}>Run on New and Unprocessed Row</MenuItem>
                        <MenuItem icon={<IconPlayerPlay size="15px" />} iconSpacing={2} onClick={() => {
                            setModal(
                                undefined
                            )
                        }}>Empty and failed</MenuItem>
                        <MenuItem icon={<IconRepeat size="15px" />} iconSpacing={2} onClick={() => {
                            setModal(
                                <ConfirmModal caption="Re-run all operations" question="Are you sure you want to re-run all operations on all visible rows?" onOk={() => {
                                    onOpAction(undefined, "rerun").then(() => {
                                        setModal(undefined)
                                    })
                                }} onCancel={() => {
                                    setModal(undefined)
                                }} />
                            )
                        }}>All visible rows</MenuItem>


                    </MenuList>

                </Menu>

            </HStack>
            {getListOpsAccordion(listOps)}


            {scheduledOps?.length && <Stack>
                <HStack justify="space-between">
                    <Text fontWeight={600} color="gray">Pending</Text>

                </HStack>
                <Accordion allowToggle index={undefined} >
                    {scheduledOps?.map((op, i) => (
                        <AccordionItem key={i} border="1px solid lightgray" borderRadius={4} mt="4px" >
                            {({ isExpanded }) => (
                                <>
                                    <HStack>
                                        {/* <Text fontSize={10}>{JSON.stringify(op)}</Text> */}
                                        <AccordionButton p="2px"  >
                                            <AccordionIcon />
                                            <Stack flexGrow={1} align="start">
                                                <Text >{op.name || op.op_type}</Text>

                                            </Stack>

                                        </AccordionButton>
                                        <HStack m="0px 8px">
                                            {getStateIcon(op.state)}
                                            <IconButton className="row-btn" aria-label="ss" size="xs" variant="ghost" icon={<IconX size="15px" />} onClick={() => {
                                                setWaiting(true)
                                                onOpAction(op, "cancel").finally(() => {
                                                    setWaiting(false)
                                                })
                                            }} />
                                        </HStack>
                                    </HStack>
                                    <AccordionPanel p="0px">
                                        <Stack spacing={1} p="8px">
                                            <Text fontSize="md" fontWeight={900} mb="-8px">{op.op_type}</Text>
                                            {isExpanded && op.kwargs && Object.keys(op.kwargs).length && (
                                                <AutoUI value={op.kwargs} />
                                            )}

                                        </Stack>

                                    </AccordionPanel>
                                </>)}
                        </AccordionItem>
                    ))}
                </Accordion>

            </Stack>}

            <HStack justify="space-between" m="10px 0px" p="4px 8px" border="1px solid gray" borderRadius="8px" shadow="md" backgroundColor="gray.50">
                {currentOp && <Text fontWeight={900}>Current: {currentOp.name}</Text>}
                <HStack justifySelf="end">
                    {getStateIcon(executorState as any)}  <Text fontSize="xs">Execution {executorState}</Text>
                    <Button size="xs" variant="outline" colorScheme="red" onClick={() => {
                        onExecutorAction(executorState == "running" ? "stop" : "resume")
                    }}>{executorState == "running" ? "Stop" : "Resume"}</Button>
                </HStack>
            </HStack>

        </Stack>

    </Stack>
    )
}


export default function ListEditPage() {



    const navigate = useNavigate()
    const { listId, listVersion } = useParams()


    const [searchParams, setSearchParams] = useSearchParams()
    const pageNo: number = parseInt(searchParams.get("page")) || 1



    const listRequest = useApiFetch<DataListInfo>(`/lists/${listId}`, {
        queryArgs: { page: pageNo, version: listVersion },
        shouldFetch: !!listId && listId != "new", swrOptions: { revalidateOnFocus: false, refreshInterval: 0 }
    })


    const setPageNo = (pageNo: number) => {
        if (pageNo <= 0) {
            if (listRequest.data?.total_count) {
                pageNo = Math.floor(listRequest.data?.total_count / 100)
            }
            else {
                return
            }
        }
        if (Math.floor(listRequest.data?.total_count / 100) + 1 < pageNo) {
            pageNo = 1
        }

        const newSearchParams = new URLSearchParams(searchParams);

        // Update the 'page' query parameter
        newSearchParams.set('page', pageNo.toString());

        setSearchParams(newSearchParams)
    }
    useEffect(() => {
        if (listRequest?.data?.page && listRequest?.data?.page != pageNo) {
            setPageNo(listRequest?.data?.page)
        }
        if (listRequest.data?.data_page?.length == 0 && pageNo > 1) {
            setPageNo(1)
        }
    }, [listRequest])

    const [modal, setModal] = useState<any>()

    const [currentOp, setCurrentOp] = useState<any>()
    const [statusMessage, setStatusMessage] = useState<{ status_type: string, message?: string, metadata: any }>(undefined)
    const [listPreviewAwaitingApproval, setListPreviewAwaitingApproval] = useState<ListPreview>()
    const [availableCommands, setAvailableCommands] = useState<{
        add_column?: CommandSectionDescription,
        filter?: CommandSectionDescription,
        import?: CommandSectionDescription,
        others?: CommandSectionDescription
    }>()
    const allAvailableCommands = useMemo(() => {
        let res = Object.values(availableCommands || {}).reduce((acc, val) => {
            return { ...acc, ...val }
        }, {})
        return res
    }, [availableCommands])
    const [filters, setFilters] = useState<{ [key: string]: any }>()




    useEffect(() => {
        if (listRequest.data?.name)
            document.title = listRequest.data?.name + " - EasyPie.ai"
        return () => {
            document.title = "EasyPie.ai"
        }
    }, [listRequest.data])


    const [listName, setListName] = useState<string>()

    const refreshData = listRequest.mutate
    const [runningAction, setRunningAction] = useState<string>()
    const [pendingUpdate, setPendingUpdate] = useState<any[]>()
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([])
    const [chatService, _setChatService] = useState<ChatService>()
    const [interrupting, setInterrupting] = useState<boolean>()
    const [hidePreview, setHidePreview] = useState<boolean>()
    const [hiddenColumnsDefault, setHiddenColumnsDefault] = useState<string[]>()
    const [columnOrderOverride, _setColumnOrderOverride] = useState<string[]>()
    const [currentListData, setCurrentListData] = useState<DataListInfo>()
    const [data, setData] = useState<{ [key: string]: any }[]>()
    useEffect(() => { setData(currentListData?.data_page) }, [currentListData])

    const reorderProperties = (columnSchema, columnOrderOverride) => {
        if (!columnSchema || !columnSchema.properties || !Array.isArray(columnOrderOverride)) {
            return columnSchema;
        }
        const { properties } = columnSchema;
        const reorderedProperties = {};
        columnOrderOverride.forEach((column) => {
            if (properties.hasOwnProperty(column)) {
                reorderedProperties[column] = properties[column];
            }
        });

        Object.keys(properties).forEach((key) => {
            if (!reorderedProperties.hasOwnProperty(key)) {
                reorderedProperties[key] = properties[key];
            }
        });

        return {
            ...columnSchema,
            properties: reorderedProperties,
        };
    };


    useEffect(() => {
        if (listRequest.data) {
            setListName(listRequest.data?.name)
            setCurrentListData(listRequest.data)
        }
        else if (currentListData) {
            setCurrentListData(undefined)

        }
    }, [listRequest.data])
    useEffect(() => { currentListData?.filter && setFilters(currentListData.filter) }, [currentListData?.filter])
    const [columnsSchema, setColumnsSchema] = useState<JSONSchema>()
    useEffect(() => {
        if (currentListData?.column_schema) {
            if (columnOrderOverride) {
                setColumnsSchema(reorderProperties(currentListData?.column_schema, columnOrderOverride))
            }
            else {
                setColumnsSchema(currentListData?.column_schema)
            }
        }
    }, [currentListData?.column_schema, columnOrderOverride])

    function updateCurrentListData(dataUpdate: any[], schema?: JSONSchema) {
        if (currentListData) {
            if (!Array.isArray(dataUpdate)) return;


            let newCurrentListData = [...currentListData.data_page]
            let newRows = []
            let changed = false
            // Iterate over each object in the dataUpdate array
            dataUpdate.forEach(updateObj => {
                // Check if the updateObj has an 'index' property
                if (updateObj && updateObj.index !== undefined) {
                    // Find the object in data_page with the same index
                    const index = updateObj.index;
                    const existingObjArrayIndex = newCurrentListData.findIndex(obj => obj.index === index);
                    const existingObj = newCurrentListData[existingObjArrayIndex];

                    if (existingObj) {
                        // Update the object as a patch
                        Object.assign(existingObj, updateObj);
                        changed = true
                    }
                    else if (newCurrentListData.length < 100) {
                        newRows.push(updateObj);
                    }


                }
            });
            if (newRows.length) {
                newCurrentListData = [...newCurrentListData, ...newRows]
            }
            if (changed) {
                setCurrentListData({ ...currentListData, data_page: newCurrentListData, column_schema: schema || currentListData.column_schema })
            }
        }
    }

    function onDisconnect(reason: any) {
        setStatusMessage(undefined)
    }
    function setChatService(service: ChatService) {
        if (service != chatService) {

            _setChatService(service)
            if (service) {
                service.subscribe({ "onDisconnect": onDisconnect })
                return () => { service.unsubscribe({ "onDisconnect": onDisconnect }) }
            }
        }

    }
    function showVersions() {
        setModal(<InfoModal caption="Versions" withFullscreenSwitch onClose={() => { setModal(undefined) }}>
            <ListVersionsComponent listId={listId} onRestored={() => {
                setModal(undefined)
                refreshData()

            }} />
        </InfoModal>)
    }
    useEffect(() => {
        if (currentListData) {

            let _newHiddenCols = hiddenColumns ? [...hiddenColumns] : []

            currentListData.columns.filter((col) => col.default_hidden && !hiddenColumns.includes(col.name)).forEach((col) => {
                _newHiddenCols.push(col.name)
            })
            setHiddenColumnsDefault(_newHiddenCols)
            if (!hiddenColumns?.length) {

                setHiddenColumns(_newHiddenCols)
            }
        }
    }, [currentListData])

    const { data: initStatusData, isLoading: initIsLoading, mutate: refreshInitStatus } = useSWR<any>(`/lists/${listId}/status/${!!chatService}`, () => {
        if (chatService && !listVersion) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(chatService.sendCommand({ "type": "command", "action_id": "hello", "args": {} }, { waitForResult: true }))
                }, 5000)
            })

        }
    }
        , { revalidateOnFocus: false, refreshInterval: 0 })

    useEffect(() => {
        if (initStatusData) {


            setCurrentOp(initStatusData.current_op)

                    setAvailableCommands(initStatusData.commands)
            //setFilters(initStatusData.filters)
            setScheduledOps(initStatusData.scheduled_ops)
            setExecutorState(initStatusData.executor_state)


        }
    }, [initStatusData])

    function callCommand(commandId: string, args: any, timeout: number = 120) {
        setCurrentOp(true)
        chatService.sendCommand({ "type": "command", "action_id": commandId, "args": args }, { waitForResult: true, returnResultMessage: true, timeoutSec: timeout }).then((res) => {
            setCurrentOp(false)
            if (res.error) {
                toast({
                    title: "Error executing command",
                    description: res.error,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            else if (res.result.widget_type) {


                    setModal(<InfoModal caption={res.result.title} onClose={() => { setModal(undefined) }}>
                        <WidgetContainer widget={res.result} isLastMessage={true} onExecuteAction={(actionId, val) => {
                            if (res.result.actions?.length && res.result.actions[0].args) {
                                callCommand(actionId, val)

                            }
                        }} />
                </InfoModal>)

            }
            else {

                setModal(undefined)
            }
            console.log(res)
        }).catch((err) => {
            console.error(err)
            setCurrentOp(false)
        })
    }
    const closeDialogCallback = useCallback(() => {
        setModal(undefined)
        setCurrentOp(false)
        setListPreviewAwaitingApproval(undefined)
    }, [])
    function showCommandDialog(section: "add_column" | "filter" | "others", commandId: string) {
        //if (availableCommands[section] && availableCommands[section][commandId] && availableCommands[section][commandId].schema?.properties && Object.keys(availableCommands[section][commandId].schema?.properties)?.length) {
        if ((!["Create campaign", "Edit list metadata", "Get list stats", "Copy list to account", "Versions"].includes(commandId))) {
                setModal(<ListOpDialog
                    opType={commandId}
                    list_id={listId}
                    chatService={chatService}
                    interactive={availableCommands[section][commandId]?.interactive}
                    supports_prompting={availableCommands[section][commandId]?.supports_prompting}
                    supports_preview={availableCommands[section][commandId]?.supports_preview}
                    argSchema={availableCommands[section][commandId]?.schema}
                    onClose={closeDialogCallback}


                />)
            }
            else
            setModal(<EditModal caption={commandId} value={{}} onOk={(args) => {
                callCommand(commandId, args)
            }}
                onCancel={() => {
                    setModal(undefined)
                }}
            >
                {(val, setVal) => (

                    <AutoUI value={val} onValueChange={setVal} schema={availableCommands[section][commandId].schema} />
                )}
            </EditModal>)
        // }
        // else {
        //     callCommand(commandId, {})

        // }
    }
    const tableBackgroundCss = `
    repeating-linear-gradient(
        to right,
        transparent,
        transparent 250px,
        #c8c8c85f 250px,
        #c8c8c85f 251px
    ),
    repeating-linear-gradient(
        to bottom,
        transparent,
        transparent 30px,
        #c8c8c8a0 30px,
        #c8c8c8a0 31px,
        #ffffffa1 32px,
        #ffffffa1 61px,
        #c8c8c8a0 62px,
        #c8c8c8a0 63px
    ),
    linear-gradient(-45deg, white,#eaeaeaa0);
    `

    const restoreVersionEndpoint = useApiEndpoint<any>("POST", `/lists/${listId}/versions/${listVersion}/restore`, true, true)
    const campaignsEndpoint = useApiFetch<{ id: string, name: string }[]>(`/campaigns?list_id=${listId}`)
    const exportEndpoint = useApiEndpoint<Blob>("GET", `/lists/${listId}/export?format={format}`, true, true, { outputType: "blob" })
    function exportList(format: "csv" | "xlsx" | "json") {
        exportEndpoint.execute({ format: format }).then((data) => {
            exportEndpoint.execute({ format: format }).then(blob => {

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `list-${listId}.${format}`;
                a.click();
            })
        })
    }

    // const statusUpdateCallback = useCallback((statusMessage: StatusMessage) => { })
    // useEffect(() => {
    //     chatService.subscribe({

    //         onStatusUpdate: statusUpdateCallback
    //     })
    // }, [chatService])
    const toast = useToast()

    const [isSaving, setIsSaving] = useState(false)

    function save() {
        if (pendingUpdate && data) {
            let deletedItems = data.filter((item, i) => !pendingUpdate.find(c => c.index === item.index))
            let patch = pendingUpdate.map((item, i) => {
                if (!data.find(c => JSON.stringify(c) === JSON.stringify(item))) {
                    return item
                }
            }).filter((item) => item !== undefined)
            console.log("patch", patch)
            console.log("delete", deletedItems)
            if (patch.length || deletedItems.length) {
                setIsSaving(true)
                chatService.sendCommand({ "type": "command", "action_id": "update-data", "args": { "patch": patch, "delete": deletedItems, "page": pageNo } }, { waitForResult: true }).then((res) => {
                    setData(res)
                    setIsSaving(false)
                    setPendingUpdate(undefined)
                }).catch((err) => {
                    setIsSaving(false)
                    toast({
                        title: "Error updating list",
                        description: "An error occurred while updating the list, please reload the page and try again.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,

                    })
                })
            }
        }
        if (listName != listRequest.data.name) {
            saveMetadata({ name: listName })
        }
    }
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (pendingUpdate && data) {
    //             save()
    //         }
    //     }, 2000)
    //     return () => {
    //         clearTimeout(timeout)
    //     }
    // }, [pendingUpdate])

    const [executorState, setExecutorState] = useState<"running" | "ready" | "paused">()
    const [scheduledOps, setScheduledOps] = useState<ListOp[]>()


    function findAvailableCommand(op_type: string) {
        return allAvailableCommands[op_type]
    }


    const handleWidgetRendering = ((widget: any, msg, editable) => {
        if (widget.widget_type == "list_ops") {

            return <ListOpsWidget widget={widget}
                listId={listId}
                chatService={chatService}
                allAvailableCommands={allAvailableCommands}
                setModal={setModal}

            />

        }
    })



    const setColumnOrderOverride = (newOrder) => {

        _setColumnOrderOverride(newOrder)

    }

    function saveMetadata(payload: { name?: string, list_category?: string, change_column_visibility?: { [colName: string]: boolean } }) {
        chatService.sendCommand({ "type": "command", "action_id": "update-metadata", "args": payload }, { waitForResult: true }).then((res) => {
            if (payload.change_column_visibility) {
                setHiddenColumnsDefault(Object.keys(payload.change_column_visibility).filter((colName) => !payload.change_column_visibility[colName]))
            }
            if (payload.name) {
                listRequest.mutate({ ...listRequest.data, name: payload.name })
            }
        })
    }

    return (<Flex className="page" direction="column" align="stretch" flexGrow={1} justify="stretch" >
        {/* <ListProvider columns={currentListData?.columns.map(c => c.name)} list_data={currentListData?.data_page}>
            <CampaignBuilder />

        </ListProvider> */}
        <HStack justify="space-between">
            <HStack>

                {!listName ? <Text>Loading...</Text> :
                    <Editable value={listName} ml="10px" >
                        <EditablePreview flexShrink={1} flexGrow={0} textOverflow="ellipsis" whiteSpace="nowrap" />
                        <EditableInput width={`${listName?.length}ch`} flexShrink={1} flexGrow={1} onChange={(e) => setListName(e.target.value)} />
                    </Editable>}
                {(pendingUpdate || listName != listRequest.data?.name) && <ButtonGroup>
                    <Button isLoading={isSaving} onClick={() => save()} size="sm" colorScheme="brand" >Save</Button>
                    <Button isDisabled={isSaving} onClick={() => {
                        setPendingUpdate(undefined)
                        listRequest.mutate()
                        setListName(listRequest.data.name)
                    }} size="sm" colorScheme="blackAlpha" >Undo</Button>
                </ButtonGroup>
                }
            </HStack>

            {(currentOp || statusMessage?.status_type == "typing") && <Portal> <HStack height="10px" p="15px" position="fixed" top="10px" left="calc(50% - 220px)" zIndex={901}>


                {!statusMessage?.message && <HStack>
                    <Spinner size="sm" />
                    <Text>
                    {typeof (currentOp) === "string" ? currentOp : "Processing..."}
                    </Text>
                </HStack>
                }

                {(statusMessage) && <Stack p="0px 5px" justify="stretch" minW="400px">
                    <GeniouslyThemeProvider>
                        <ChatStatusBadge statusMessage={statusMessage} />
                    </GeniouslyThemeProvider>


                </Stack>}
                {/* <Text>{JSON.stringify(statusMessage)}</Text> */}
                {statusMessage?.metadata.executor_state == "running" && <Button size="xs" ml="-50px"
                            isLoading={interrupting}
                            onClick={() => chatService.sendCommand({ "type": "command", "action_id": "stop-process", "args": {} }, { waitForResult: true, returnResultMessage: true, timeoutSec: 120 }).then((res) => {
                                setInterrupting(false)

                                if (!res.success) {
                                    toast({
                                        title: "Interruption failed",

                                        status: "error",
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                } else {
                                    refreshInitStatus()
                                }
                            })}
                > Stop </Button>}

            </HStack>
            </Portal>
            }
            <HStack p="0px 10px" flexShrink={0} fontWeight={500} fontSize="sm">


                {!modal && !listVersion && 
                    <ButtonGroup size="sm">
                        {!availableCommands && <Spinner opacity={0.6} size="sm" m="0px 10px" alignSelf="center" justifySelf="center" />}

                        {/* Import */}
                        {<Menu size="sm">
                            <MenuButton variant="outline"

                                leftIcon={<LuImport size="18px" />}
                                colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                                Import
                            </MenuButton>
                            <MenuList zIndex={15}>

                                <Text m="0px 5px">List sources</Text>
                                <Stack maxH="50vh" spacing={0} overflow="auto">
                                {listRequest?.data?.sources?.map((op, i) => (

                                    <MenuItem key={i} onClick={() => {
                                        // setModal(<ListOpDialog list_id={listId} opType={op.op_type} listOp={op as any} argSchema={op.arg_schema} argValues={op.kwargs} chatService={chatService} onClose={() => { setModal(undefined) }} />)
                                    }} >

                                        <HStack border="1px solid lightgray" borderRadius={4} p="4px" width="100%" justify="space-between">
                                            <Tooltip label={op.name} >
                                                <Text maxW="200px" noOfLines={1}>
                                                    {op.name}
                                                </Text>
                                            </Tooltip>
                                            <HStack>
                                                <Tag colorScheme="black" size="sm" color="white">
                                                    <TagLabel>{op.imported_count || 0} / {op.total_records || "?"}</TagLabel>
                                                </Tag>

                                                {(op.state != "done" || (op.total_records && op.total_records) > (op.processed_count || 0)) && <Tooltip label="Import more" >
                                                    <IconButton size="xs" icon={<IconPlayerPlay size="15px" />} aria-label="Play" onClick={() => {
                                                        setModal(<RunOptionsDialog
                                                            onCancel={() => { setModal(undefined) }}
                                                            onOk={(options: any) => {
                                                                chatService.sendCommand({ "type": "command", "action_id": "op-rerun", "args": { "op_id": op.id, "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                    if (res.error || !res.success) {
                                                                        toast({
                                                                            title: "Error re-running operation",
                                                                            description: res.error,
                                                                            status: "error",
                                                                            duration: 9000,
                                                                            isClosable: true,
                                                                        })
                                                                    }
                                                                    else {
                                                                        setModal(undefined)
                                                                    }

                                                                })
                                                            }} />)


                                                    }} />

                                                </Tooltip>}
                                                <IconButton size="xs" icon={<IconX size="15px" />} aria-label="Delete" onClick={() => {
                                                    setModal(<ConfirmModal caption="Delete operation" question="Are you sure you want to delete this source and its data from the list?"
                                                        onCancel={() => { setModal(undefined) }}
                                                        onOk={() => {

                                                            chatService.sendCommand({ "type": "command", "action_id": "delete-list-source", "args": { "list_source_id": op.id } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                if (res.error || !res.success) {
                                                                    toast({
                                                                        title: "Error while deleting source",
                                                                        description: res.error,
                                                                        status: "error",
                                                                        duration: 9000,
                                                                        isClosable: true,
                                                                    })
                                                                }
                                                                else {
                                                                    setModal(undefined)
                                                                }

                                                            })
                                                        }} />)


                                                }} />

                                            </HStack>
                                        </HStack>
                                    </MenuItem>
                                ))}
                                </Stack>

                                <MenuDivider />

                                {availableCommands?.import && Object.keys(availableCommands?.import).map((cmdName) => (

                                    <MenuItem key={cmdName} onClick={() => showCommandDialog("others", cmdName)}>{availableCommands?.import[cmdName].name}</MenuItem>
                                ))}



                            </MenuList>
                        </Menu>}

                        {/* Filter */}
                        {availableCommands?.filter && Object.keys(availableCommands?.filter) && <FilterButton
                            filters={filters}

                            filterOps={filter && Object.keys(availableCommands?.filter).map((cmdId) => ({
                                id: cmdId,
                                name: availableCommands?.filter[cmdId].name
                            }))}
                            isLoading={runningAction == "toggle_filter"}
                            onFilterOp={(cmdId) => showCommandDialog("filter", cmdId)}
                            onToggleFilter={(filterName, val) => {

                                setRunningAction("toggle_filter")

                                chatService.sendCommand({ "type": "command", "action_id": "update-metadata", "args": { "toggle_filters": { [filterName]: val } } }, { waitForResult: true }).then((res) => {
                                    setRunningAction("undefined")

                                    refreshData()

                                })
                            }}
                            onRemoveFilter={(filterName) => {
                                let newFilters = { ...filters }
                                delete newFilters[filterName]
                                chatService.sendCommand({ "type": "command", "action_id": "delete-filter", "args": { "filter": filterName } }, { waitForResult: true }).then((res) => {

                                    setFilters(newFilters)
                                    refreshData()

                                })
                            }}

                        />}



                        {/* Add column */}
                        {availableCommands?.add_column && Object.keys(availableCommands?.add_column) && <Menu size="sm">
                            <MenuButton variant="outline"

                                leftIcon={<IconPlus size="18px" />}
                                colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                                Add column
                            </MenuButton>
                            <MenuList zIndex={15}>
                                {availableCommands?.add_column && Object.keys(availableCommands?.add_column).map((cmdId) => (

                                    <MenuItem key={cmdId} onClick={() => showCommandDialog("add_column", cmdId)}>{availableCommands?.add_column[cmdId].name}</MenuItem>
                                ))}

                            </MenuList>
                        </Menu>}


                        {/* Others */}
                        {availableCommands?.others && Object.keys(availableCommands?.others) && <Menu size="sm">
                            <MenuButton variant="outline" as={IconButton}

                                icon={<IconDotsVertical size="18px" />}
                                colorScheme="brand" />


                            <MenuList zIndex={15}>
                                {availableCommands?.others && Object.keys(availableCommands?.others).filter(cmdName => cmdName !== "CreateCampaign").map((cmdName) => (

                                    <MenuItem key={cmdName} onClick={() => showCommandDialog("others", cmdName)}>{availableCommands?.others[cmdName].name}</MenuItem>
                                ))}


                            </MenuList>

                        </Menu>}


                        {/* Campaigns */}
                        <Menu size="sm">
                            <MenuButton variant="outline"

                                leftIcon={<IconMailFast size="25px" />}
                                colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                                {campaignsEndpoint?.data?.length ? "Campaigns" : "New campaign"}
                            </MenuButton>
                            {availableCommands && <MenuList zIndex={15}>
                                <Text fontWeight={700} m="4px">Campaigns</Text>
                                {campaignsEndpoint?.data?.map((campaign, i) => (

                                    <MenuItem p="1px" key={i} onClick={() => {
                                        setModal(<CampaignEditor campaign={campaign as any} chatService={chatService} onClose={() => { setModal(undefined) }} />)
                                    }} >
                                        <HStack m="2px 4px" p="4px 8px" border="1px solid lightgray" borderRadius={6}>
                                            <Text>

                                            {campaign.name}
                                            </Text>
                                        </HStack>
                                    </MenuItem>
                                ))}
                                <MenuDivider />
                                <MenuItem as={Button} variant="outline" m="2px" colorScheme="brand" leftIcon={<IconPlus />} key={-1} onClick={() => {
                                    showCommandDialog("others", "CreateCampaign")
                                }}>New campaign</MenuItem>
                            </MenuList>}

                        </Menu>


                        <Menu size="sm">
                            <MenuButton as={IconButton}
                                onClick={() => {

                                    chatService.sendCommand({ "type": "command", "action_id": "get-executor-state", "args": {} }, { waitForResult: true }).then((res) => {
                                        setExecutorState(res.executor_state)
                                        setScheduledOps(res.scheduled_ops)
                                        setCurrentOp(res.current_op)

                                        setModal(<ConfirmModal caption="Operations" onOk={() => { setModal(undefined) }}>
                                            <ListOpsForm listOps={listRequest?.data.operations} openVersions={() => showVersions()} importOps={listRequest?.data.sources} executorState={res.executor_state} scheduledOps={res.scheduled_ops}
                                                onExecutorAction={(action) => {
                                                    return chatService.sendCommand({ "type": "command", "action_id": "change-executor-state", "args": { "change_state": action } }, { waitForResult: true }).then(() => {
                                                        refreshData()
                                                        setModal(undefined)
                                                    })
                                                }}
                                                onOpAction={(op, action, options) => {
                                                    if (op == "all") {
                                                        if (action == "rerun") {
                                                            return chatService.sendCommand({ "type": "command", "action_id": "op-rerun-all", "args": { "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                if (res.error || !res.success) {
                                                                    toast({
                                                                        title: "Error re-running operation",
                                                                        description: res.error,
                                                                        status: "error",
                                                                        duration: 9000,
                                                                        isClosable: true,
                                                                    })
                                                                }
                                                                else {
                                                                    setModal(undefined)
                                                                }

                                                            })
                                                        }
                                                    }
                                                    else if (action === "rerun") {
                                                        return new Promise((resolve, reject) => {

                                                            chatService.sendCommand({ "type": "command", "action_id": "op-rerun", "args": { "op_id": op.id, "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                if (res.error || !res.success) {
                                                                    toast({
                                                                        title: "Error re-running operation",
                                                                        description: res.error,
                                                                        status: "error",
                                                                        duration: 9000,
                                                                        isClosable: true,
                                                                    })
                                                                }
                                                                else {
                                                                    setModal(undefined)
                                                                }

                                                    })
                                                        })

                                                    }
                                                    else if (action === "retry") {
                                                        let cmd = findAvailableCommand(op.op_type)
                                                        return new Promise((resolve, reject) => {
                                                            setModal(<ListOpDialog list_id={listId} opType={op.op_type} listOp={op as any} argSchema={op.arg_schema} argValues={op.kwargs} chatService={chatService}
                                                                interactive={cmd.interactive}
                                                                supports_preview={cmd.supports_preview}
                                                                supports_prompting={cmd.supports_prompting}
                                                                onClose={closeDialogCallback} />)
                                                        })
                                                    }
                                                    else if (action === "cancel") {
                                                        return chatService.sendCommand({ "type": "command", "action_id": "change-op-state", "args": { "op_id": op.id, "change_state": action } }, { waitForResult: true }).then(() => {
                                                            refreshData()
                                                            setModal(undefined)
                                                        })
                                                    }

                                                }}

                                            />
                                        </ConfirmModal>)
                                    })
                                }}
                                icon={<HStack m="0px 6px"><IconStack2 size="18px" />{executorState == "running" ? <Spinner size="xs" /> : (executorState == "paused" ? (<IconPlayerPauseFilled size="18px" />) : <></>)}</HStack>}
                            />
                        </Menu>
                        <Tooltip label="Export" aria-label="Export">
                            <Menu size="sm" >
                                <MenuButton as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />} p="2px 6px" >
                                    <IconFileDownload size="22px" color="darkslategray" />
                        </MenuButton>
                                <MenuList zIndex={15} fontWeight={500}>
                            <MenuItem onClick={() => exportList("csv")}>Export to csv</MenuItem>
                            <MenuItem onClick={() => exportList("xlsx")}>Export to xlsx</MenuItem>
                            <MenuItem onClick={() => exportList("json")}>Export to json</MenuItem>
                        </MenuList>
                    </Menu>
                        </Tooltip>
                        {/* <Button
                        onClick={() => {}}
                        >Share</Button> */}
                        <ColumnsSelector
                            pendingSave={!!columnOrderOverride || (hiddenColumns && hiddenColumnsDefault && hiddenColumnsDefault.length !== hiddenColumns.length && new Set(hiddenColumns) !== new Set(hiddenColumnsDefault))}
                            columns={currentListData?.columns?.map((col) => col.name)}
                            hiddenColumns={hiddenColumns}
                            saveMetadata={saveMetadata}
                            setColumnOrder={setColumnOrderOverride}
                            setHiddenColumns={setHiddenColumns}
                            columnOrder={columnOrderOverride}
                        />


                    </ButtonGroup>} 

                {
                    listVersion && (
                        <ButtonGroup>
                            <Button size="sm"
                                variant="outline"
                                leftIcon={<IconChevronLeft size="15px" />}
                                colorScheme="brand"
                                isDisabled={restoreVersionEndpoint.isRunning}
                                onClick={() => {
                                    navigate(`/lists/${listId}`)
                                }}
                            >Back to current version</Button>

                            <Button size="sm"
                                colorScheme="brand"
                                isLoading={restoreVersionEndpoint.isRunning}
                                onClick={() => {
                                    restoreVersionEndpoint.execute({}).then(() => {
                                        navigate(`/lists/${listId}`)
                                    })
                                }}
                            >Restore this version</Button>
                        </ButtonGroup>
                    )}


            </HStack>
        </HStack>



        <GeniouslyThemeProvider>
            <ListProvider columns={currentListData?.columns} list_data={currentListData?.data_page}>



            {modal}
            {(listRequest.isLoading) ? <Stack m="50px" minHeight="40vh" width="auto"
                align="center" justify={"center"}
                border="1px solid #c8c8c8" borderRadius={"5px"}
            >

                {listRequest.isLoading ? (
                    <Box opacity={0.4}>
                        <Spinner size="xl" />
                    </Box>
                ) : (

                    <Stack direction="column" spacing="10px" width="50%" p="4px" border="c8c8c8" shadow="dark-lg" borderRadius="4px" background="white">


                        <ChatInput placeholderText="What list are we gonna build?" onSend={() => { }} />

                    </Stack>
                )}
            </Stack>
                : (
                    <Stack m="2px" width="auto" align="center" justify={"start"}

                        // background={data?.length ? "white" : tableBackgroundCss}
                    >

                        <Stack border="5px solid #c8c8c8" borderRadius={"5px"} height="calc(100vh - 165px)" width="100%" background={!data?.length ? tableBackgroundCss : "white"}>

                        <AutoUI
                            layout="table"
                                onValueChange={(val) => {
                                    setPendingUpdate(val)
                                }}

                                    value={pendingUpdate || data}

                                excludeFields={hiddenColumns}
                                    schema={columnsSchema ? { type: "array", items: columnsSchema } : undefined}
                            />


                            </Stack>
                            {!listPreviewAwaitingApproval && <HStack justify="end" alignSelf="end">
                            <Stack align="end" spacing={0}>
                                <Menu >
                                    <MenuButton as={Button} size="xs" rightIcon={<IconChevronDown size="15px" />}>
                                        {100 * (pageNo - 1) + 1}-{100 * (pageNo) > currentListData?.total_count ? currentListData?.total_count : 100 * (pageNo)}
                                    </MenuButton>
                                    <MenuList  >

                                        {Array.from({ length: Math.floor(currentListData?.total_count / 100) + 1 }, (_, i) => i * 100).map((startRange) => (

                                            <MenuItem fontSize="xs" justifySelf="start" key={startRange} onClick={() => setPageNo((startRange / 100) + 1)}>{startRange} - {startRange + 100 > currentListData.total_count ? currentListData.total_count : startRange + 100}</MenuItem>
                                        ))}

                                    </MenuList>
                                </Menu>
                                <Text fontSize="xs">Total rows: {currentListData?.total_count}</Text>

                            </Stack>
                            <IconButton aria-label="Previous page" icon={<IconChevronLeft />} onClick={() => setPageNo(pageNo - 1)} />
                            <IconButton aria-label="Next page" icon={<IconChevronRight />} onClick={() => setPageNo(pageNo + 1)} />
                        </HStack>}

                        {!listVersion && <Stack direction="column" spacing="0px" width="50%" position="absolute" bottom={0}  >



                            <GeniouslyCommander

                                appId={"default"}
                                open={modal ? false : undefined}


                                onSessionChanged={(sessionId) => {
                                    if (sessionId != listId) {
                                        navigate(`/chat/${sessionId}`)
                                    }
                                }}
                                renderWidgets={handleWidgetRendering}

                                onStatusUpdate={(statusMsg: StatusMessage) => {
                                    if (statusMsg?.status_type != "waiting") {
                                        if (!statusMsg.metadata?.preview && statusMsg.metadata.executor_state) {

                                            updateCurrentListData(statusMsg.metadata.update, statusMsg.metadata.schema)
                                            setExecutorState(statusMsg.metadata.executor_state)
                                            setScheduledOps(statusMsg.metadata.scheduled_ops)
                                            setStatusMessage(statusMsg as any)
                                        }
                                        if (statusMsg.metadata?.current_op?.state && !["init", "running"].includes(statusMsg.metadata?.current_op?.state)) {
                                            listRequest.mutate()
                                        }
                                    }                                    
                                    else {
                                        setCurrentOp(false)
                                        setStatusMessage(undefined)
                                        if (!statusMsg.metadata?.preview && statusMsg.metadata.executor_state) {

                                            updateCurrentListData(statusMsg.metadata.update, statusMsg.metadata.schema)
                                            setExecutorState(statusMsg.metadata.executor_state)
                                            setScheduledOps(statusMsg.metadata.scheduled_ops)
                                            setStatusMessage(statusMsg as any)
                                        }
                                    }
                                }}
                                onNewMessageReceived={(msg) => {
                                    setStatusMessage(undefined)
                                    if (msg?.widgets?.length && msg.widgets[0].widget_type == "list_preview") {
                                        setCurrentOp(false)
                                        setListPreviewAwaitingApproval(msg.widgets[0] as any)
                                    }
                                }}

                                onExecuteAction={(actionId, args) => {
                                    if (actionId == "refresh") {
                                        refreshData()
                                        refreshInitStatus()


                                        return Promise.resolve({ success: true })
                                    }

                                }}
                                apiUrl={process.env.REACT_APP_API_URL}
                                sessionId={listRequest?.data?.id}
                                visitorId={"-"}
                                inputPlaceholder={statusMessage ? (statusMessage.message || statusMessage.status_type) : (data?.length ? "What data should we add to this list?" : "What should we do with this data ?")}
                                useChatService={(service) => setChatService(service as ChatService)}
                                collectContext={() =>
                                    Promise.resolve({
                                        localTime: new Date().toString(),
                                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })
                                }
                                authDataRetriever={() => getApi().authorize()}
                            //onExecuteAction={(actionId, args) => onExecuteAction(actionId, args)}
                            />
                        </Stack>}

                    </Stack>

                )}
            {/* <AutoUI layout="table" value={[]} schema={{ type: "object", properties: { name: { type: "string" }, age: { type: "number" } } }} /> */}

            </ListProvider> 
        </GeniouslyThemeProvider>


    </Flex>

    )
}

const CampaignEditor = ({ campaign, onClose, chatService }: { chatService: any, campaign: Campaign, onClose: () => any }) => {
    const [error, setError] = useState<string>()
    function onSave(campaign: Campaign) {
        return chatService.sendCommand({
            "type": "command", "action_id": "accept-preview", "args": {
                "list_op": {
                    op_type: "CreateCampaign",
                    stages: [{ id: "init", name: "init", args: { campaign: campaign } }]
                }
                ,
            }
        }, { waitForResult: true, returnResultMessage: true }).then((res: CommandResultMessage) => {
            if (res.success) {
                onClose()
            }
            else if (res.error) {
                setError(res.error)
            }
            else if (res.result?.command_accepted) {
                setError("Error approving changes")
            }
        })
    }
    return (
        <CampaignBuilder campaign={campaign} error={error} onCancel={onClose} onOk={onSave} />

    )
}

